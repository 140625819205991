import React, { ReactNode, useEffect, useState } from 'react'

import styled, { useTheme } from 'styled-components'

import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'

import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import WarningIcon from '@material-ui/icons/WarningOutlined'

import Typography from '@app/components/atoms/Typography/Typography'
import InputEndAdornment from '@app/components/atoms/InputEndAdornment/InputEndAdornment'
import Tooltip from '@app/components/atoms/Tooltip/Tooltip'

import TextField, {
  TextFieldPriceFormatter,
} from '@app/components/atoms/TextField/TextField'

type NestedRow = {
  id: string
  title: string
  value: string
  onChange?: (nextValue: string) => void | Promise<void>
}

interface PriceRowItemProps {
  name: string
  title: string
  readonly?: boolean
  value?: string
  onChange?: (nextValue: string) => void | Promise<void>
  preview?: string
  isPreviewWarning?: boolean
  isPreviewTooltipOpen?: boolean
  onPreviewTooltipOpen?: () => void
  onPreviewTooltipClose?: () => void
  previewTooltip?: ReactNode
  info?: ReactNode
  nestedRows?: NestedRow[]
  isProfit?: boolean
  disablePreviewFormatting?: boolean
  defaultIsExpanded?: boolean
  collapseContent?: ReactNode
}

const LegPriceItemRow = ({
  name,
  title,
  value,
  onChange,
  preview,
  isPreviewWarning,
  isPreviewTooltipOpen,
  onPreviewTooltipOpen,
  onPreviewTooltipClose,
  previewTooltip,
  info,
  nestedRows,
  isProfit,
  readonly,
  disablePreviewFormatting,
  collapseContent,
  defaultIsExpanded = false,
}: PriceRowItemProps): JSX.Element => {
  // @todo Make as prop event instead ???
  const [isExpanded, setIsExpanded] = useState(defaultIsExpanded)
  const [fieldValue, setFieldValue] = useState(value || '0')

  const [nestedRowsValues, setNestedRowsValues] = useState(
    nestedRows?.map((row) => row.value),
  )

  const theme = useTheme()

  const onExpandButtonClick = () => {
    setIsExpanded(!isExpanded)
  }

  useEffect(() => {
    setFieldValue(value || '0')
  }, [value])

  useEffect(() => {
    if (nestedRows) {
      setNestedRowsValues(nestedRows.map((row) => row.value))
    }
  }, [nestedRows])

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pt={1}
      >
        <Box display="flex" alignItems="center">
          <Typography variant="content">{title}</Typography>

          {(nestedRows || collapseContent) && (
            <StyledIconButton
              onClick={onExpandButtonClick}
              data-testid={`LegPriceRowItem__${name}-expand-row-button`}
            >
              {isExpanded ? <ExpandLess /> : <ExpandMore />}
            </StyledIconButton>
          )}
        </Box>

        <Box display="flex" alignItems="center">
          {info && <Box mr={1}>{info}</Box>}

          {preview && (
            <StyledTooltip
              backgroundColor="#fff"
              borderColor={theme.palette.grey[300]}
              title={previewTooltip ?? ''}
              open={isPreviewTooltipOpen}
              onClose={() => onPreviewTooltipClose?.()}
              interactive
            >
              <StyledTextField
                disabled
                value={preview}
                warning={isPreviewWarning}
                inputComponent={
                  disablePreviewFormatting ? undefined : TextFieldPriceFormatter
                }
                inputProps={{
                  'data-testid': `LegPriceRowItem__${name}-preview-input`,
                }}
                endAdornment={
                  isPreviewWarning ? (
                    <InputEndAdornment onClick={() => onPreviewTooltipOpen?.()}>
                      <StyledWarningIcon />
                    </InputEndAdornment>
                  ) : undefined
                }
              />
            </StyledTooltip>
          )}

          {value !== undefined && (
            <StyledTextField
              $isProfit={isProfit}
              $isNegative={Number(value) < 0}
              disabled={readonly}
              value={fieldValue}
              onChange={(event) => {
                setFieldValue(event.target.value || '0')
              }}
              onBlur={() => {
                onChange?.(fieldValue)
              }}
              inputComponent={TextFieldPriceFormatter}
              inputProps={{
                'data-testid': `LegPriceRowItem__${name}-value-input`,
              }}
            />
          )}
        </Box>
      </Box>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        {collapseContent}

        {nestedRows?.map((nestedRow, index) => (
          <Box
            key={index}
            display="flex"
            justifyContent="space-between"
            mt={1}
            pl={3}
          >
            <Typography variant="content">{nestedRow.title}</Typography>

            <StyledTextField
              disabled={readonly}
              value={nestedRowsValues?.[index] ?? '0'}
              onChange={(event) =>
                setNestedRowsValues((current) =>
                  current?.map((nestedRowValue, nestedRowIndex) =>
                    nestedRowIndex === index
                      ? event.target.value
                      : nestedRowValue,
                  ),
                )
              }
              onBlur={() =>
                nestedRow.onChange?.(nestedRowsValues?.[index] ?? '0')
              }
              inputComponent={TextFieldPriceFormatter}
              classes={{
                input: 'LegPriceRow__input',
              }}
              inputProps={{
                'data-testid': `LegPriceRowItem__${name}-nested-row-${nestedRow.id}-input`,
              }}
            />
          </Box>
        ))}
      </Collapse>
    </Box>
  )
}

const StyledIconButton = styled(IconButton)`
  margin-left: 0.25rem;
  padding: 5px;
`

const StyledTextField = styled(TextField)<{
  $isProfit?: boolean
  $isNegative?: boolean
}>`
  margin-left: 0.25rem;
  width: 5rem;

  // @todo Use classes instead
  & input {
    color: ${({ $isProfit, $isNegative, theme }) =>
      $isProfit &&
      ($isNegative ? theme.palette.error.main : theme.palette.success.main)};

    -webkit-text-fill-color: ${({ $isProfit, $isNegative, theme }) =>
      $isProfit &&
      ($isNegative ? theme.palette.error.main : theme.palette.success.main)};

    font-size: 0.9rem;
    text-align: right;
    opacity: 1;
  }
`

const StyledWarningIcon = styled(WarningIcon)`
  color: ${({ theme }) => theme.palette.warning.main};
`

const StyledTooltip = styled(Tooltip)`
  .Tooltip__popper {
    width: 30rem;
  }

  .Tooltip__tooltip {
    max-width: none;
  }
`

export default LegPriceItemRow
