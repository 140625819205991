import React, { Fragment, ReactNode, useMemo, useState } from 'react'
import styled from 'styled-components'
import InfiniteScroll from 'react-infinite-scroll-component'
import { usePrevious, useUpdateEffect } from 'react-use'

import {
  CellProps as BaseCellProps,
  Column as BaseColumn,
  ColumnInstance,
  Row,
  useRowSelect,
  useTable,
} from 'react-table'

import Table from '@material-ui/core/Table'
import Collapse from '@material-ui/core/Collapse'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import GetAppIcon from '@material-ui/icons/GetApp'

import Typography from '@app/components/atoms/Typography/Typography'

import { useRowSelectionColumn } from '@app/components/atoms/DataGrid/useRowSelectionColumn'
import { OrderDirection } from '@app/constants'

import { DEFAULT_ORDER_DIRECTION } from '@shared/v2/common/constants'

import {
  ContextMenuItem,
  useRowActionColumn,
} from '@app/components/atoms/DataGrid/useRowActionColumn'
import { Tooltip } from '@material-ui/core'

interface CellProps<T extends object> extends BaseCellProps<T> {
  column: ColumnInstance<T> & { title: string }
}

type Sort = {
  id: string
  direction:
    | Lowercase<typeof OrderDirection.Desc>
    | Lowercase<typeof OrderDirection.Asc>
}

export type DataGridColumn<T extends object> = BaseColumn<T> & {
  title?: string
  isSortable?: boolean
  headerIcon?: JSX.Element
}

export interface DataGridProps<T extends object> {
  columns: BaseColumn<T>[]
  data: T[]
  cellProps?: Record<string, unknown>
  hasMore?: boolean
  className?: string
  isSelectable?: boolean
  isExportable?: boolean
  isSelectAllEnabled?: boolean
  getContextMenuItems?: (entity: T) => ContextMenuItem[] | undefined
  getBadgeNotificationContent?: (entity: T) => number | string | null
  collapsedRowId?: number | null
  dataTestIdNamespace: string
  onTableRowClick?: (id: number) => Promise<void> | void
  getCollapsedContainer?: (collapsedRowId: number | null) => ReactNode
  onNextDataRequested?: () => void
  onExportButtonClick?: (items: T[]) => void
  sort?: Sort | null
  defaultSortDirection?: 'asc' | 'desc'
  onSortChange?: (nextSort: Sort | null) => void
  onSelectionChange?: (
    selectedFlatRows: Row<T>[],
    selectedRowIds: Record<string, boolean>,
  ) => void
}

export const DATA_GRID_DATA_ROW_ID_ATTRIBUTE = 'data-row-id'

function DataGrid<T extends { id: number }>({
  columns,
  data,
  cellProps,
  className,
  onExportButtonClick,
  onSelectionChange,
  onNextDataRequested,
  collapsedRowId = null,
  dataTestIdNamespace,
  onTableRowClick,
  getCollapsedContainer,
  sort,
  onSortChange,
  hasMore = false,
  getContextMenuItems,
  getBadgeNotificationContent,
  isExportable = false,
  isSelectable = false,
  isSelectAllEnabled = isSelectable,
  defaultSortDirection = DEFAULT_ORDER_DIRECTION,
  ...props
}: DataGridProps<T>): JSX.Element {
  const [updatingSortId, setUpdatingSortId] = useState<string | null>(null)

  const {
    getTableProps,
    headers,
    prepareRow,
    rows,
    selectedFlatRows,
    state: { selectedRowIds },
  } = useTable<T>(
    {
      defaultColumn: {
        Cell: DefaultBodyCell,
        Header: DefaultHeaderCell,
        width: 0,
        disableSortBy: true,
      },
      data,
      columns,
    },
    useRowSelect,
    useRowSelectionColumn(isSelectable),
    useRowActionColumn(getContextMenuItems, getBadgeNotificationContent),
  )

  const previousSelectedFlatRows = usePrevious(selectedFlatRows)

  useUpdateEffect(() => {
    if (previousSelectedFlatRows?.length === selectedFlatRows.length) {
      return
    }

    onSelectionChange?.(selectedFlatRows, selectedRowIds)
  }, [selectedFlatRows, selectedRowIds])

  const updateActiveSort = async (columnId: string) => {
    let nextDirection: Sort['direction'] | null = null

    if (sort?.id !== columnId) {
      nextDirection = defaultSortDirection
    }

    if (sort?.id === columnId && sort.direction === defaultSortDirection) {
      nextDirection = defaultSortDirection === 'desc' ? 'asc' : 'desc'
    }

    const nextSort =
      nextDirection === null
        ? null
        : {
            direction: nextDirection,
            id: columnId,
          }

    setUpdatingSortId(columnId)

    onSortChange?.(nextSort)
  }

  const handleExportButtonClick = () => {
    let targetRows = rows

    if (isSelectable && Object.keys(selectedRowIds).length !== 0) {
      targetRows = targetRows.filter((row) => row.isSelected)
    }

    onExportButtonClick?.(targetRows.map((row) => row.original))
  }

  const collapsedContainer = useMemo(() => {
    if (!collapsedRowId) {
      return
    }

    return getCollapsedContainer?.(collapsedRowId)
  }, [collapsedRowId])

  return (
    <DataGridContainer className={className} {...props}>
      <InfiniteScroll
        hasMore={hasMore}
        dataLength={rows.length}
        next={() => onNextDataRequested?.()}
        loader={<Loader />}
      >
        <TableContainer>
          <Table {...getTableProps()}>
            <TableHead>
              <DataGridHeadRow>
                {headers.map((column) => (
                  <DataGridHeadCell
                    {...column.getHeaderProps({
                      style: {
                        cursor: column.isSortable ? 'pointer' : 'default',
                        minWidth: column.minWidth,
                        width: column.width || 'initial',
                      },
                    })}
                    data-testid={`${dataTestIdNamespace}__header-column-${column.id}`}
                    key={column.id}
                    onClick={() => {
                      if (column.isSortable) {
                        updateActiveSort(column.id)
                      }
                    }}
                  >
                    {column.render('Header', {
                      withSelectAll: isSelectable && isSelectAllEnabled,
                    })}

                    {column.addorment}
                    {column.id !== 'selection' && column.isSortable && (
                      <SortContainer>
                        {updatingSortId === column.id && (
                          <StyledCircularProgress size={15} />
                        )}

                        <TableSortLabel
                          direction={sort?.direction ?? defaultSortDirection}
                          active={!updatingSortId && sort?.id === column.id}
                        />
                      </SortContainer>
                    )}
                  </DataGridHeadCell>
                ))}
              </DataGridHeadRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIndex) => {
                prepareRow(row)

                return (
                  <Fragment key={row.original.id}>
                    <DataGridRow
                      {...row.getRowProps()}
                      {...{
                        [DATA_GRID_DATA_ROW_ID_ATTRIBUTE]: row.original.id,
                      }}
                      $isSelectable={Boolean(onTableRowClick)}
                      onClick={() => {
                        onTableRowClick?.(row.original.id)
                      }}
                      data-testid={`${dataTestIdNamespace}__row[${rowIndex}]`}
                    >
                      {row.cells.map((cell, cellIndex) => {
                        return (
                          <DataGridCell
                            {...cell.getCellProps({
                              style: {
                                minWidth: cell.column.minWidth,
                                width: cell.column.width || 'initial',
                              },
                            })}
                            key={cellIndex}
                          >
                            {cell.render('Cell', {
                              data: row.original,
                              isSelectable,
                              ...cellProps,
                            })}
                          </DataGridCell>
                        )
                      })}
                    </DataGridRow>
                    {collapsedContainer && (
                      <TableRow>
                        <CollapsedContainerCell
                          style={{ border: 0, paddingBottom: 0, paddingTop: 0 }}
                          colSpan={9}
                        >
                          <Collapse
                            in={row.original.id === collapsedRowId}
                            timeout="auto"
                            unmountOnExit
                          >
                            {row.original.id === collapsedRowId &&
                              collapsedContainer}
                          </Collapse>
                        </CollapsedContainerCell>
                      </TableRow>
                    )}
                  </Fragment>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </InfiniteScroll>
      {isExportable && (
        <ExportIconButton onClick={handleExportButtonClick}>
          <GetAppIcon />
        </ExportIconButton>
      )}
    </DataGridContainer>
  )
}

const Loader = () => (
  <DataGridLoaderContainer
    container
    alignItems="center"
    justifyContent="center"
  >
    <CircularProgress size={20} />
  </DataGridLoaderContainer>
)

const DefaultHeaderCell = <T extends object>({
  column,
}: CellProps<T>): JSX.Element => {
  return <HeaderTypography variant="title">{column.title}</HeaderTypography>
}

const DefaultBodyCell = <T extends object>({
  cell,
}: CellProps<T>): JSX.Element => {
  return <Typography variant="content">{cell.value}</Typography>
}

export const SimpleCell = <T extends object>({
  cell,
}: CellProps<T>): JSX.Element => {
  const columnId = cell.column.id

  // eslint-disable-next-line
  const tooltip: string | undefined = ((cell.row.original as any) || {})[
    'tooltip_' + columnId
  ]

  const cellRender = (
    <Typography variant="content">
      {cell.row.original[columnId as keyof T] ?? '-'}
    </Typography>
  )

  if (tooltip) {
    return (
      <Tooltip title={tooltip} placement="bottom-start">
        <div>{cellRender}</div>
      </Tooltip>
    )
  }

  return cellRender
}

const HeaderTypography = styled(Typography)`
  display: inline;
`

const ExportIconButton = styled(IconButton)`
  position: absolute;
  right: 20px;
  top: 0px;
`

const SortContainer = styled.div`
  position: relative;
  display: inline;
`

const StyledCircularProgress = styled(CircularProgress)`
  bottom: 0;
  position: absolute;
  right: 5px;
  top: 0;
`

const DataGridContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 3rem;

  & > div {
    width: inherit;
  }
`

export const DataGridHeadCell = styled(TableCell)`
  padding-top: 0;
  padding-bottom: 0.25rem;
`

export const DataGridHeadRow = styled(TableRow)`
  height: 3rem;
`

const CollapsedContainerCell = styled(TableCell)`
  padding: 0;
`

export const DataGridCell = styled(TableCell)`
  white-space: nowrap;
`

const DataGridLoaderContainer = styled(Grid)`
  height: 3rem;
`

export const DataGridRow = styled(TableRow)<{
  $isSelectable: boolean
}>`
  cursor: ${({ $isSelectable }) => $isSelectable && 'pointer'};
`

export default DataGrid
