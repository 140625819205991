import { PostContactPersonDto } from '@app/utils/api/types'
import { ContactPersonFormData } from '@app/components/organisms/ContactPersonForm/ContactPersonForm'
import { ContactPersonDetailDto } from '@shared/dto/contactPerson.dto'

export const transformContactPersonToFormData = (
  contactPerson: ContactPersonDetailDto | null,
): ContactPersonFormData => {
  return {
    name: contactPerson?.name ?? '',
    email: contactPerson?.email ?? '',
    phone: contactPerson?.phone ?? '',
    mobilePhone: contactPerson?.mobile_phone ?? '',
  }
}

export const transformFormDataToPostContactPersonDto = (
  formData: ContactPersonFormData,
): Omit<PostContactPersonDto, 'client_id'> => {
  return {
    name: formData.name,
    email: formData.email,
    phone: formData.phone || undefined,
    mobile_phone: formData.mobilePhone || undefined,
  }
}
