import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { theme } from '@app/theme'

import Box from '@material-ui/core/Box'

import Typography from '@app/components/atoms/Typography/Typography'
import Price from '@app/components/atoms/Price/Price'

import { SimilarRequestItem } from '@app/components/organisms/SimilarRequestsSection/SimilarRequestsSection'
import { Routes } from '@shared/enums'

type SimilarRequestRowProps = {
  data: SimilarRequestItem
}

interface OfferStatuses {
  [key: string]: string
}

const SimilarRequestRow = ({ data }: SimilarRequestRowProps) => {
  const { t } = useTranslation()

  const offerStatuses: OfferStatuses = {
    new: theme.colors.orange,
    unhandled: theme.colors.orange,
    draft: theme.colors.blue,
    declined: theme.colors.brown,
    quoted: theme.colors.purple,
    rejected: theme.colors.red,
    booked: theme.colors.green,
    'booked-cancelled': theme.colors.grey,
  }

  const offerStatusColor =
    data.offer_status && offerStatuses[data.offer_status]
      ? offerStatuses[data.offer_status]
      : 'black'

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box display="flex" flexDirection="column" flex="1">
        {!data.client_name && !data.company_name ? (
          <SecondaryTypography>
            {t('organisms.SimilarRequestsSectionRow.noClientSelected')}
          </SecondaryTypography>
        ) : (
          <>
            {data.client_name && (
              <PrimaryTypography customColor={offerStatusColor}>
                {data.client_name}
              </PrimaryTypography>
            )}
            {data.company_name && (
              <SecondaryTypography customColor={offerStatusColor}>
                {data.company_name}
              </SecondaryTypography>
            )}
          </>
        )}
      </Box>
      <Box flex="1">
        <PrimaryTypography customColor={offerStatusColor}>
          {data.id ? (
            <Link
              href={Routes.Request.replace(':requestId', data.id.toString())}
              target="_blank"
            >
              {data.trip_id}
            </Link>
          ) : (
            <>{data.trip_id}</>
          )}
        </PrimaryTypography>
      </Box>
      <Box display="flex" flexDirection="column" flex="1">
        <PrimaryTypography customColor={offerStatusColor}>
          {data.aircraft_registration_code}
        </PrimaryTypography>
        <Box display="flex">
          <Box mr="0.5rem">
            <StyledPrice
              type={data.profit < 0 ? 'loss' : 'profit'}
              value={data.profit}
            />
          </Box>
          <StyledPrice value={data.total_price} />
        </Box>
      </Box>
    </Box>
  )
}

const StyledPrice = styled(Price)`
  font-size: 0.9rem;
`

const PrimaryTypography = styled(Typography)`
  font-size: 0.9rem;
  color: ${({ customColor, theme }) =>
    customColor || theme.palette.text.primary};
`

const Link = styled.a`
  color: inherit;
`

/**
 * @todo [refactor] create Typography variant
 */
const SecondaryTypography = styled(Typography)`
  font-size: 0.8rem;
  color: ${({ customColor, theme }) => customColor || theme.palette.grey[400]};
`

export default SimilarRequestRow
