import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import FlightIcon from '@material-ui/icons/FlightOutlined'
import AirlineStopsIcon from '@material-ui/icons/CallMissedOutlined'
import ClearIcon from '@material-ui/icons/Clear'
import SyncIcon from '@material-ui/icons/Sync'

import SplitLegForm from '@app/components/organisms/LegEditorForm/SplitLegForm/SplitLegForm'

import { AirportDetailDto } from '@shared/dto/airports.dto'

export interface LegEditorContextMenuProps {
  className?: string
  onDeleteClick?: () => void
  onFlightTimeRecalculateClick?: () => void
  onCancelDeleteClick?: () => void
  onMakeFerryClick?: () => void
  onMakeEmptyClick?: () => void
  onSplitLegClick?: (airport: AirportDetailDto) => void
  onIgnoreSingleLegFromOptimization?: () => void
  onIgnoreAllLegsFromOptimization?: () => void
}

const LegEditorContextMenu = ({
  className,
  onDeleteClick,
  onFlightTimeRecalculateClick,
  onCancelDeleteClick,
  onMakeFerryClick,
  onMakeEmptyClick,
  onSplitLegClick,
  onIgnoreSingleLegFromOptimization,
  onIgnoreAllLegsFromOptimization,
}: LegEditorContextMenuProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Wrapper className={className}>
      {onMakeEmptyClick && (
        <StyledMenuItem
          onClick={onMakeEmptyClick}
          data-testid="LegEditorContextMenu__make-empty-context-menu-item"
        >
          <MenuItemHeader>
            <StyledListItemIcon>
              <StyledFlightIcon />
            </StyledListItemIcon>
            <ListItemText>
              {t('molecules.LegEditorContextMenu.makeEmpty')}
            </ListItemText>
          </MenuItemHeader>
          <MenuItemDescription>
            {t('molecules.LegEditorContextMenu.makeEmptyDescription')}
          </MenuItemDescription>
        </StyledMenuItem>
      )}
      {onFlightTimeRecalculateClick && (
        <StyledMenuItem
          onClick={onFlightTimeRecalculateClick}
          data-testid="LegEditorContextMenu__flight-time-recalculate-context-menu-item"
        >
          <MenuItemHeader>
            <StyledListItemIcon>
              <StyledSyncIcon />
            </StyledListItemIcon>
            <ListItemText>
              {t('molecules.LegEditorContextMenu.FlightTimeRecalculate')}
            </ListItemText>
          </MenuItemHeader>
          <MenuItemDescription>
            {t(
              'molecules.LegEditorContextMenu.FlightTimeRecalculateDescription',
            )}
          </MenuItemDescription>
        </StyledMenuItem>
      )}
      {onMakeFerryClick && (
        <StyledMenuItem
          onClick={onMakeFerryClick}
          data-testid="LegEditorContextMenu__make-ferry-context-menu-item"
        >
          <MenuItemHeader>
            <StyledListItemIcon>
              <StyledFlightIcon />
            </StyledListItemIcon>
            <ListItemText>
              {t('molecules.LegEditorContextMenu.makeFerry')}
            </ListItemText>
          </MenuItemHeader>
          <MenuItemDescription>
            {t('molecules.LegEditorContextMenu.makeFerryDescription')}
          </MenuItemDescription>
        </StyledMenuItem>
      )}
      {onSplitLegClick && (
        <SplitLegMenuItem>
          <SplitLegMenuHeader>
            <StyledListItemIcon>
              <StyledAirlineStopsIcon />
            </StyledListItemIcon>
            <ListItemText>
              {t('molecules.LegEditorContextMenu.splitLeg')}
            </ListItemText>
          </SplitLegMenuHeader>
          <MenuItemDescription>
            {t('molecules.LegEditorContextMenu.splitLegDescription')}
          </MenuItemDescription>
          <StyledSplitLegForm onSubmit={onSplitLegClick} />
        </SplitLegMenuItem>
      )}
      {onDeleteClick && (
        <StyledMenuItem
          onClick={onDeleteClick}
          data-testid="LegEditorContextMenu__delete-leg-context-menu-item"
        >
          <DeleteMenuItemHeader>
            <DeleteListItemIcon>
              <StyledDeleteIcon />
            </DeleteListItemIcon>
            <ListItemText>
              {t('molecules.LegEditorContextMenu.delete')}
            </ListItemText>
          </DeleteMenuItemHeader>
          <MenuItemDescription>
            {t('molecules.LegEditorContextMenu.deleteDescription')}
          </MenuItemDescription>
        </StyledMenuItem>
      )}
      {onCancelDeleteClick && (
        <StyledMenuItem
          onClick={onCancelDeleteClick}
          data-testid="LegEditorContextMenu__cancel-leg-removal-context-menu-item"
        >
          <MenuItemHeader>
            <StyledListItemIcon>
              <StyledDeleteForeverIcon />
            </StyledListItemIcon>
            <ListItemText>
              {t('molecules.LegEditorContextMenu.cancelDelete')}
            </ListItemText>
          </MenuItemHeader>
          <MenuItemDescription>
            {t('molecules.LegEditorContextMenu.cancelDeleteDescription')}
          </MenuItemDescription>
        </StyledMenuItem>
      )}
      {onIgnoreSingleLegFromOptimization && (
        <StyledMenuItem
          onClick={onIgnoreSingleLegFromOptimization}
          data-testid="LegEditorContextMenu__ignore-single-leg-from-optimization-context-menu-item"
        >
          <MenuItemHeader>
            <StyledListItemIcon>
              <StyledClearIcon />
            </StyledListItemIcon>
            <ListItemText>
              {t(
                'molecules.LegEditorContextMenu.ignoreSingleLegsFromOptimization',
              )}
            </ListItemText>
          </MenuItemHeader>
          <MenuItemDescription>
            {t(
              'molecules.LegEditorContextMenu.ignoreSingleLegsFromOptimizationDescription',
            )}
          </MenuItemDescription>
        </StyledMenuItem>
      )}
      {onIgnoreAllLegsFromOptimization && (
        <StyledMenuItem
          onClick={onIgnoreAllLegsFromOptimization}
          data-testid="LegEditorContextMenu__ignore-all-legs-from-optimization-context-menu-item"
        >
          <MenuItemHeader>
            <StyledListItemIcon>
              <StyledClearIcon />
            </StyledListItemIcon>
            <ListItemText>
              {t(
                'molecules.LegEditorContextMenu.ignoreAllLegsFromOptimization',
              )}
            </ListItemText>
          </MenuItemHeader>
          <MenuItemDescription>
            {t(
              'molecules.LegEditorContextMenu.ignoreAllLegsFromOptimizationDescription',
            )}
          </MenuItemDescription>
        </StyledMenuItem>
      )}
    </Wrapper>
  )
}

const StyledClearIcon = styled(ClearIcon)`
  font-size: 1.2rem;
`

const StyledMenuItem = styled(MenuItem)`
  flex-direction: column;
  align-items: flex-start;

  .MuiTypography-body1 {
    font-size: 1rem;
  }
`

const MenuItemHeader = styled.div`
  display: flex;
  color: ${({ theme }) => theme.palette.primary.main};
  align-items: center;
`

const DeleteMenuItemHeader = styled(MenuItemHeader)`
  color: ${({ theme }) => theme.colors.red};
`

const MenuItemDescription = styled.div`
  color: ${({ theme }) => theme.palette.grey[600]};
  font-size: 0.8rem;
`

const SplitLegMenuItem = styled.div`
  padding: 6px 16px;
`

const SplitLegMenuHeader = styled.div`
  color: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  align-items: center;
`

const StyledListItemIcon = styled(ListItemIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
  min-width: 2rem;
`

const DeleteListItemIcon = styled(StyledListItemIcon)`
  color: ${({ theme }) => theme.colors.red};
`

const StyledDeleteIcon = styled(DeleteIcon)`
  font-size: 1.2rem;
`

const StyledDeleteForeverIcon = styled(DeleteForeverIcon)`
  font-size: 1.2rem;
`

const StyledAirlineStopsIcon = styled(AirlineStopsIcon)`
  font-size: 1.2rem;
`

const StyledFlightIcon = styled(FlightIcon)`
  font-size: 1.2rem;
`

const StyledSyncIcon = styled(SyncIcon)`
  font-size: 1.2rem;
`

const StyledSplitLegForm = styled(SplitLegForm)`
  margin: 1rem 0;
`

const Wrapper = styled.div`
  min-width: 4rem;
  min-height: 1rem;
`

export default LegEditorContextMenu
