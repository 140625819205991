import { useSelector } from 'react-redux'

import { selectUserInfo } from '@app/store/core/userInfo/userInfo.selectors'
import { selectSelectedOperator } from '@app/store/core/userOperators/userOperators.selectors'
import { Actions, UserStatuses } from '@shared/enums'
import { canUser } from '@shared/utils/authUtils'

export const useCanUser = () => {
  const userInfo = useSelector(selectUserInfo)
  const selectedOperator = useSelector(selectSelectedOperator)

  return (action: Actions, operatorId?: number) => {
    if (!userInfo?.user_roles || !selectedOperator) {
      return false
    }

    const activeRoles = userInfo.user_roles.filter(
      (role) => role.status === UserStatuses.Active,
    )

    return canUser(action, activeRoles, operatorId ?? selectedOperator.id)
  }
}
