import React, { useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { darken, lighten } from '@material-ui/core/styles'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

import Box from '@material-ui/core/Box'
import WarningIcon from '@material-ui/icons/Warning'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'

import Tooltip from '@app/components/atoms/Tooltip/Tooltip'
import LegPriceRowItem from '@app/components/organisms/LegPriceBreakdown/LegPriceRowItem'
import Typography from '@app/components/atoms/Typography/Typography'
import Button from '@app/components/atoms/Button/Button'
import AirportTooltip from '@app/components/molecules/AirportTooltip/AirportTooltip'
import FeesManager from '@app/components/molecules/FeesManager/FeesManager'

import useGetAirportIdentificationCode from '@app/hooks/useGetAirportIdentificationCode'
import { OtherCostUnits } from '@shared/v2/types/offer.types'
import { Actions, LegTypes, OfferStatuses } from '@shared/enums'
import { BaseLegDetailDto } from '@shared/dto/requests.dto'
import { SIMPLE_DURATION_FORMAT } from '@shared/constants'
import { getLegColor } from '@app/utils/stylingUtils'
import { PostCustomRouteDto } from '@app/utils/api/types'
import { AircraftDetailDto } from '@shared/dto/aircraft.dto'
import { useCanUser } from '@app/hooks/useCanUser'
import { CustomRouteDetailDto } from '@shared/dto/customRoutes.dto'

import {
  getLegAirportBudget,
  getLegAirportFee,
  getLegArrivalFee,
  getLegCateringFee,
  getLegDepartureFee,
  getLegFlightTimeProfit,
  getLegHandlingFee,
  getLegOtherCosts,
  getLegPassengerFee,
  getLegVariableCost,
} from '@shared/v2/utils/price.utils'
import { useAirportNote } from '@app/hooks/useAirportNote'

export enum AirportFeeInputIds {
  AirportFee = 'airport_fee',
  ArrivalFee = 'arrival_fee',
  DepartureFee = 'departure_fee',
  HandlingFee = 'handling_fee',
}

export interface LegPriceBreakdownProps {
  index: number
  leg: BaseLegDetailDto
  aircraft: AircraftDetailDto
  offerStatus: OfferStatuses
  readonly?: boolean
  withPassengerFees?: boolean
  className?: string
  onCreateCustomRouteClick: (postCustomRouteDto: PostCustomRouteDto) => void
  isEditAirportFeeIconVisible: boolean
  onEditAirportFeesClick: () => void
  offerRelatedCustomRoutes: CustomRouteDetailDto[] | null
  onChange?: (
    index: number,
    id: keyof BaseLegDetailDto,
    value: unknown, // @todo Automatically infer type based on value of id
  ) => void | Promise<void>
}

dayjs.extend(duration)

const LegPriceBreakdown = ({
  index,
  leg,
  offerStatus,
  onChange,
  isEditAirportFeeIconVisible,
  onEditAirportFeesClick,
  readonly,
  className,
  onCreateCustomRouteClick,
  aircraft,
  offerRelatedCustomRoutes,
}: LegPriceBreakdownProps): JSX.Element => {
  const { t } = useTranslation()
  const theme = useTheme()
  const getAirportCode = useGetAirportIdentificationCode()
  const canUser = useCanUser()

  const getAirportNote = useAirportNote()
  const canUserUpdateAirportFee = canUser(Actions.UpdateAirportFee)
  const canUserUpdateCustomRoutes = canUser(Actions.UpdateCustomRoute)

  const [isCustomRouteTooltipOpen, setIsCustomRouteTooltipOpen] =
    useState(false)

  const [isAirportFeesTooltipOpen, setIsAirportFeesTooltipOpen] =
    useState(false)

  const relevantCustomRoute = offerRelatedCustomRoutes?.find(
    (customRoute) =>
      [aircraft.id, null].includes(customRoute.aircraft_id) &&
      customRoute.departure_airport_id === leg.departure_airport_id &&
      customRoute.arrival_airport_id === leg.arrival_airport_id,
  )

  const isCustomRouteTooltipAvailable = [
    OfferStatuses.New,
    OfferStatuses.Unhandled,
    OfferStatuses.Draft,
  ].includes(offerStatus)

  const handleCreateCustomRouteForAircraftClick = () => {
    onCreateCustomRouteClick({
      departure_airport_id: leg.departure_airport_id,
      arrival_airport_id: leg.arrival_airport_id,
      aircraft_id: aircraft.id,
      operator_id: aircraft.operator_id,
      flight_time_in_minutes: leg.duration_in_minutes,
      distance: leg.distance_in_nautical_miles,
      airway_id: leg.airway_id ?? null,
    })

    setIsCustomRouteTooltipOpen(false)
  }

  const handleCreateCustomRouteForFleetClick = () => {
    onCreateCustomRouteClick({
      departure_airport_id: leg.departure_airport_id,
      arrival_airport_id: leg.arrival_airport_id,
      operator_id: aircraft.operator_id,
      flight_time_in_minutes: leg.duration_in_minutes,
      distance: leg.distance_in_nautical_miles,
      airway_id: leg.airway_id ?? null,
    })

    setIsCustomRouteTooltipOpen(false)
  }

  return (
    <Box p={1} display="flex" className={className}>
      <Box pt={2.25} px={1} lineHeight={1.5}>
        {t('molecules.LegPriceBreakdown.title', { index: index + 1 })}
      </Box>
      <Box
        pt={2.25}
        px={1}
        display="flex"
        flexDirection="column"
        position="relative"
      >
        <Circle $color={getLegColor(theme, leg.type, offerStatus)}>-</Circle>
        <Line
          $color={getLegColor(theme, leg.type, offerStatus)}
          $type={leg.type}
        />
      </Box>
      <Box
        pt={0.5}
        pl={1}
        display="flex"
        flexDirection="column"
        flex={1}
        position="relative"
      >
        <LegPriceRowItem
          name="variableHourlyCost"
          disablePreviewFormatting
          readonly={readonly}
          title={t('molecule.legPriceBreakdown.variableHourlyCost')}
          value={String(getLegVariableCost(leg))}
          isPreviewWarning={
            isCustomRouteTooltipAvailable &&
            leg.duration_in_minutes !== leg.original_duration_in_minutes &&
            leg.duration_in_minutes !==
              relevantCustomRoute?.flight_time_in_minutes
          }
          onChange={(nextValue) =>
            onChange?.(index, 'variable_cost', Number(nextValue) || 0)
          }
          preview={dayjs
            .duration(
              dayjs(leg.arrival_date).diff(leg.departure_date, 'minutes'),
              'minutes',
            )
            .format(SIMPLE_DURATION_FORMAT)}
          isPreviewTooltipOpen={isCustomRouteTooltipOpen}
          onPreviewTooltipOpen={() => setIsCustomRouteTooltipOpen(true)}
          onPreviewTooltipClose={() => setIsCustomRouteTooltipOpen(false)}
          previewTooltip={
            <TooltipContainer>
              <StyledIconButton>
                <StyledCloseIcon
                  onClick={() => setIsCustomRouteTooltipOpen(false)}
                />
              </StyledIconButton>
              <Typography>
                {t('organisms.LegPriceBreakdown.saveAsCustomRoute')}
              </Typography>
              <TooltipButtons>
                <Button
                  key={index}
                  onClick={handleCreateCustomRouteForAircraftClick}
                  disabled={!canUserUpdateCustomRoutes}
                >
                  {t('organisms.LegPriceBreakdown.saveCustomRouteForAircraft')}
                </Button>
                <Button
                  key={index}
                  onClick={handleCreateCustomRouteForFleetClick}
                  disabled={!canUserUpdateCustomRoutes}
                >
                  {t('organisms.LegPriceBreakdown.saveCustomRouteForFleet')}
                </Button>
              </TooltipButtons>
            </TooltipContainer>
          }
        />

        <LegPriceRowItem
          name="profit"
          isProfit
          readonly={readonly}
          title={t('molecule.legPriceBreakdown.profit')}
          value={String(getLegFlightTimeProfit(leg))}
          onChange={(nextValue) =>
            onChange?.(index, 'profit', Number(nextValue) || 0)
          }
        />

        <LegPriceRowItem
          name="airportBudget"
          title={t('molecule.legPriceBreakdown.airportBudget')}
          readonly={readonly}
          preview={String(getLegAirportBudget(leg))}
          nestedRows={[
            {
              id: AirportFeeInputIds.AirportFee,
              title: t('molecule.legPriceBreakdown.airportFee'),
              value: String(getLegAirportFee(leg)),
              onChange: (nextValue) =>
                onChange?.(index, 'airport_fee', Number(nextValue) || 0),
            },
            {
              id: AirportFeeInputIds.HandlingFee,
              title: t('molecule.legPriceBreakdown.handlingFee'),
              value: String(getLegHandlingFee(leg)),
              onChange: (nextValue) =>
                onChange?.(index, 'handling_fee', Number(nextValue) || 0),
            },
          ]}
          info={
            <Box display="flex" alignItems="center">
              <Box mr={0.5}>
                <StyledTooltip
                  backgroundColor="#fff"
                  borderColor={theme.palette.grey[300]}
                  open={isAirportFeesTooltipOpen}
                  onClose={() => setIsAirportFeesTooltipOpen(false)}
                  interactive
                  title={
                    <TooltipContainer>
                      <Typography>
                        {t('organisms.LegPriceBreakdown.airportFeesChange')}
                      </Typography>
                      <TooltipButtons>
                        <Button
                          onClick={onEditAirportFeesClick}
                          disabled={!canUserUpdateAirportFee}
                        >
                          {t('organisms.LegPriceBreakdown.editAirportFees')}
                        </Button>
                      </TooltipButtons>
                    </TooltipContainer>
                  }
                >
                  <Box display="flex" alignItems="center">
                    {isEditAirportFeeIconVisible && (
                      <StyledWarningIcon
                        fontSize="inherit"
                        color="secondary"
                        onClick={() =>
                          setIsAirportFeesTooltipOpen((current) => !current)
                        }
                      />
                    )}
                  </Box>
                </StyledTooltip>
              </Box>

              <Tooltip
                open={isAirportFeesTooltipOpen ? false : undefined}
                title={
                  <AirportTooltip
                    airportIcao={leg.arrival_airport.icao_code}
                    airportIata={leg.arrival_airport.iata_code}
                    airportCountry={leg.arrival_airport.country}
                    airportCity={leg.arrival_airport.city}
                    airportRunways={leg.arrival_airport.extras.runways}
                    timezoneOffsetInMinutes={
                      leg.arrival_airport.timezone_offset_in_minutes
                    }
                    note={getAirportNote(leg.arrival_airport.icao_code)}
                  />
                }
              >
                <div>
                  <Typography variant="content">
                    {getAirportCode(leg.arrival_airport)}
                  </Typography>
                </div>
              </Tooltip>
            </Box>
          }
        />

        {leg.passenger_count > 0 && (
          <LegPriceRowItem
            name="passengerFees"
            title={t('molecule.legPriceBreakdown.passengerFees')}
            readonly={readonly}
            preview={String(getLegPassengerFee(leg))}
            nestedRows={[
              {
                id: 'catering_fee',
                title: t('molecule.legPriceBreakdown.cateringFee'),
                value: String(getLegCateringFee(leg)),
                onChange: (nextValue) =>
                  onChange?.(index, 'catering_fee', Number(nextValue) || 0),
              },
              {
                id: AirportFeeInputIds.DepartureFee,
                title: t('molecule.legPriceBreakdown.departureFee'),
                value: String(getLegDepartureFee(leg)),
                onChange: (nextValue) =>
                  onChange?.(index, 'departure_fee', Number(nextValue) || 0),
              },
              {
                id: AirportFeeInputIds.ArrivalFee,
                title: t('molecule.legPriceBreakdown.arrivalFee'),
                value: String(getLegArrivalFee(leg)),
                onChange: (nextValue) =>
                  onChange?.(index, 'arrival_fee', Number(nextValue) || 0),
              },
            ]}
            info={
              <Typography variant="content">
                {t('molecule.legPriceBreakdown.passengerCount', {
                  passengerCount: leg.passenger_count,
                })}
              </Typography>
            }
          />
        )}

        <LegPriceRowItem
          name="otherCost"
          title={t('molecule.legPriceBreakdown.otherCost')}
          readonly={readonly}
          preview={String(getLegOtherCosts(leg))}
          collapseContent={
            <FeesManager
              readonly={readonly}
              fees={leg.other_costs}
              getDefaultFee={(label, value) => {
                return {
                  unit: OtherCostUnits.FlatFee,
                  label,
                  value,
                }
              }}
              onChange={(nextValue) =>
                onChange?.(index, 'other_costs', nextValue)
              }
            />
          }
        />
      </Box>
    </Box>
  )
}

const Line = styled.div<{ $color: string; $type: LegTypes }>`
  height: calc(100% - 30px);
  margin-left: 3px;
  width: 4px;

  background: ${({ $color, $type }) => {
    switch ($type) {
      case LegTypes.Empty:
        return lighten($color, 0.6)

      default:
        return $color
    }
  }};
`

const Circle = styled.div<{ $color: string }>`
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background: ${({ $color }) => darken($color, 0.3)};
  color: ${({ theme }) => theme.palette.common.white};
  text-align: center;
  line-height: 0.5rem;
  font-weight: bold;
  z-index: 2;
  margin-top: 5px;
`

const TooltipContainer = styled.div`
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
`

const TooltipButtons = styled.div`
  display: flex;
  margin-top: 2rem;
  margin-bottom: 1rem;

  & > *:not(:last-child) {
    margin-right: 0.5rem;
  }
`

const StyledTooltip = styled(Tooltip)`
  .Tooltip__popper {
    width: 30rem;
  }

  .Tooltip__tooltip {
    max-width: none;
  }
`

const StyledWarningIcon = styled(WarningIcon)`
  cursor: pointer;
`

const StyledIconButton = styled(IconButton)`
  align-self: flex-end;
  width: 1rem;
  height: 1rem;
  font-size: 1rem;
`

const StyledCloseIcon = styled(CloseIcon)`
  width: 1rem;
  height: 1rem;
`

export default LegPriceBreakdown
