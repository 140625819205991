import { Actions, UserRoles } from '@shared/enums'
import { PERMISSIONS, USER_ROLE_WEIGHTS } from '@shared/constants'

interface UserRole {
  operator_id: number
  role: UserRoles
}

export const canUser = (
  action: Actions,
  userRoles: UserRole[],
  operatorId: number,
) => {
  const permittedRoles = PERMISSIONS[action]

  return userRoles.some(
    (userRole) =>
      permittedRoles.includes(userRole.role) &&
      userRole.operator_id === operatorId,
  )
}

export const getCanUserDisplayAirports = (userRoles: UserRole[]) =>
  userRoles?.some((role) => PERMISSIONS[Actions.GetAirport].includes(role.role))

export const getIsRoleSuperior = (
  initiatorRole: UserRoles,
  comparedRole: UserRoles,
) => {
  const userRoleWeight = USER_ROLE_WEIGHTS[initiatorRole]
  const targetUserRoleWeight = USER_ROLE_WEIGHTS[comparedRole]

  return targetUserRoleWeight < userRoleWeight
}
