import { UserDto } from '@shared/dto/user.dto'
import { UserSettingsFormData } from '@app/components/organisms/UserPanel/UserSettingsForm/UserSettingsForm'
import { UpdateUserDisplaySettingsDto } from '@app/utils/api/types'

export const transformUserToFormValues = (
  user: UserDto,
): UserSettingsFormData => ({
  currency: '',
  weekStart: user.display_week_start,
  dateFormat: user.display_date_format,
  timeFormat: user.display_time_format,
  timeDisplay: user.display_time_type,
  airportsIdentification: user.display_airport_identification,
})

export const transformFormValuesToUser = (
  formData: UserSettingsFormData,
): UpdateUserDisplaySettingsDto => ({
  display_week_start: formData.weekStart,
  display_date_format: formData.dateFormat,
  display_time_format: formData.timeFormat,
  display_time_type: formData.timeDisplay,
  display_airport_identification: formData.airportsIdentification,
})
