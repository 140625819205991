import dayjs from 'dayjs'
import { getMarketingLegExtensionInfo } from '@app/components/molecules/Schedule/utils'
import { LegTypes, OfferStatuses, ScheduleSource } from '@shared/enums'
import Tooltip from '@app/components/atoms/Tooltip/Tooltip'
import ScheduleTooltipContent from '@app/components/molecules/Schedule/ScheduleTooltipContent'
import { getLegTripCosts } from '@shared/utils/computationCalculator'
import React, { ChangeEvent, useState } from 'react'
import { BaseLegDetailDto } from '@shared/dto/requests.dto'
import {
  OfferProfitAndPrice,
  ScheduleDetailDto,
} from '@shared/dto/schedule.dto'
import ScheduleItem, {
  ScheduleItemVariants,
} from '@app/components/molecules/Schedule/ScheduleItem'
import useGetAirportIdentificationCode from '@app/hooks/useGetAirportIdentificationCode'
import styled, { useTheme } from 'styled-components'
import utc from 'dayjs/plugin/utc'
import { getSwimlaneScheduleItemData } from '@app/components/molecules/Schedule/swimlaneSchedule.utils'

dayjs.extend(utc)

interface ScheduleItemContainerProps {
  $left: string
  $right: string
}

interface SwimlaneScheduleProps {
  schedule: BaseLegDetailDto[] | ScheduleDetailDto[]
  variant: ScheduleItemVariants
  offerStatus?: OfferStatuses
  onConvertToMarketingLeg?: (
    id: number,
    dates: { departureDate?: Date; arrivalDate?: Date },
  ) => void
  onEditMarketingLeg?: (id: number) => void
  onEditOutage?: (id: number) => void
  onRemoveOutage?: (id: number) => void
  onMarkLegAsRemoved?: (id: number) => void
  onNavigateToRequest?: (id: number) => void
  finalPriceAndTotalProfit: OfferProfitAndPrice[]
  requestTripId?: string
  onRemoveMarketingLeg?: (id: number) => void
  viewStartDayJs: dayjs.Dayjs
  viewEndDayJs: dayjs.Dayjs
  viewStart: Date
  viewEnd: Date
  visibleDurationInMilliseconds: number
}

const TARGET_DATA_ATTRIBUTE = 'data-swimlane-id'

export const SwimlaneSchedule = ({
  schedule,
  variant,
  finalPriceAndTotalProfit,
  offerStatus,
  onConvertToMarketingLeg,
  onRemoveMarketingLeg,
  onEditMarketingLeg,
  onMarkLegAsRemoved,
  onNavigateToRequest,
  onRemoveOutage,
  onEditOutage,
  requestTripId,
  viewStart,
  viewEnd,
  viewEndDayJs,
  viewStartDayJs,
  visibleDurationInMilliseconds,
}: SwimlaneScheduleProps) => {
  const getAirportCode = useGetAirportIdentificationCode()
  const theme = useTheme()

  const [openItemsIdentification, setOpenItemsIdentification] = useState<
    string | null
  >(null)

  const getIsScheduleDetailDto = (
    item: BaseLegDetailDto | ScheduleDetailDto,
  ): item is ScheduleDetailDto => !!(item as ScheduleDetailDto).aircraft_id

  const getIsArrayOfScheduleDetailDto = (
    schedule: BaseLegDetailDto[] | ScheduleDetailDto[],
  ): schedule is ScheduleDetailDto[] => getIsScheduleDetailDto(schedule[0])

  const getTripId = (item: BaseLegDetailDto | ScheduleDetailDto) =>
    getIsScheduleDetailDto(item) ? item.trip_id : requestTripId

  const getOpenItemsIdentification = (
    item: BaseLegDetailDto | ScheduleDetailDto,
  ) => {
    if (!getIsScheduleDetailDto(item)) {
      return `${requestTripId}${item.departure_date}` ?? null
    }

    return `${item.trip_id}${item.id}` ?? String(item.id)
  }

  const getItemName = (item: BaseLegDetailDto | ScheduleDetailDto) => {
    if (!getIsScheduleDetailDto(item)) {
      return requestTripId
    }

    return (
      item.trip_id ?? item.fl3xx_trip_number?.toString() ?? item.label ?? ''
    )
  }

  const onTooltipClose = (
    event: ChangeEvent<object>,
    itemIdentification: string | null,
  ) => {
    if (!itemIdentification || openItemsIdentification !== itemIdentification) {
      return
    }

    const getIsTargetHTMLElement = (
      target: EventTarget,
    ): target is HTMLElement => !!(target as HTMLElement).childNodes

    const target = event.target

    if (!getIsTargetHTMLElement(target)) {
      return
    }

    const elements = document.querySelectorAll(
      `[${TARGET_DATA_ATTRIBUTE}="${itemIdentification}"]`,
    )

    const containsTarget = Array.from(elements).some((element) =>
      element.contains(target),
    )

    if (containsTarget) {
      return
    }

    setOpenItemsIdentification(null)
  }

  return (
    <>
      {schedule.map((scheduleItem, index) => {
        const isReserved = !!(scheduleItem as ScheduleDetailDto).reserved
        const {
          scheduleItemStart,
          scheduleItemEnd,
          marketplaceExtensionWidthPercentage,
          marketplaceExtensionStartPercentage,
        } = getSwimlaneScheduleItemData(scheduleItem)

        const wholeItemLeftPositionPercentage =
          (scheduleItemStart.diff(viewStartDayJs) /
            visibleDurationInMilliseconds) *
          100

        const wholeItemRightPositionPercentage =
          (viewEndDayJs.diff(scheduleItemEnd) / visibleDurationInMilliseconds) *
          100

        const itemTitle = getItemName(scheduleItem)

        const marketingLegConversionOptions =
          getIsArrayOfScheduleDetailDto(schedule) && onConvertToMarketingLeg
            ? getMarketingLegExtensionInfo(
                schedule,
                index,
                onConvertToMarketingLeg,
                { viewStart, viewEnd },
              )
            : undefined

        const isMarketingLeg = Boolean(
          scheduleItem.marketplace_departure_date ||
            scheduleItem.marketplace_arrival_date,
        )

        const editMarketingLeg = () => {
          if (getIsScheduleDetailDto(scheduleItem) && onEditMarketingLeg) {
            onEditMarketingLeg(scheduleItem.id)
          }
        }

        const removeMarketingLeg = () => {
          if (getIsScheduleDetailDto(scheduleItem) && onRemoveMarketingLeg) {
            onRemoveMarketingLeg(scheduleItem.id)
          }
        }

        const contextMenuActions = getIsScheduleDetailDto(scheduleItem)
          ? {
              onEditMarketingLeg: isMarketingLeg ? editMarketingLeg : undefined,

              onRemoveMarketingLeg: isMarketingLeg
                ? removeMarketingLeg
                : undefined,

              onEditOutage:
                scheduleItem.type === LegTypes.Outage &&
                onEditOutage &&
                scheduleItem.source === ScheduleSource.STRAFOS
                  ? () => onEditOutage(scheduleItem.id)
                  : undefined,

              onRemoveOutage:
                scheduleItem.type === LegTypes.Outage &&
                onRemoveOutage &&
                scheduleItem.source === ScheduleSource.STRAFOS
                  ? () => onRemoveOutage(scheduleItem.id)
                  : undefined,

              onMarkLegAsRemoved:
                scheduleItem.type === LegTypes.Empty && onMarkLegAsRemoved
                  ? () => onMarkLegAsRemoved(scheduleItem.id)
                  : undefined,
            }
          : undefined

        const tripId = getTripId(scheduleItem)
        const itemIdentification = getOpenItemsIdentification(scheduleItem)

        const isVisible =
          wholeItemLeftPositionPercentage < 100 &&
          wholeItemRightPositionPercentage < 100

        const result = finalPriceAndTotalProfit?.find(
          (item) => item.offer_id === scheduleItem.offer_id,
        )

        const finalPrice = result?.final_price ?? 0
        const totalProfit = result?.total_profit ?? 0
        const tooltipPlacement =
          wholeItemLeftPositionPercentage > 70 ? 'left' : 'right'

        return (
          <Tooltip
            {...{
              [TARGET_DATA_ATTRIBUTE]: itemTitle,
            }}
            key={index}
            backgroundColor={theme.palette.common.white}
            borderColor={theme.palette.grey[200]}
            interactive
            onClose={(event) => onTooltipClose(event, itemIdentification)}
            open={openItemsIdentification === itemIdentification}
            placement={tooltipPlacement}
            title={
              <ScheduleTooltipContent
                legType={scheduleItem.type}
                passengerCount={scheduleItem.passenger_count}
                departureDate={scheduleItem.departure_date}
                arrivalDate={scheduleItem.arrival_date}
                flightTimeInMinutes={scheduleItem.duration_in_minutes}
                profit={scheduleItem.profit}
                price={getLegTripCosts(scheduleItem).toNumber()}
                tripId={tripId}
                departureAirport={
                  getAirportCode(scheduleItem.departure_airport) ?? ''
                }
                arrivalAirport={
                  getAirportCode(scheduleItem.arrival_airport) ?? ''
                }
                distanceInNauticalMiles={
                  scheduleItem.distance_in_nautical_miles
                }
                onTripIdClick={
                  getIsScheduleDetailDto(scheduleItem) && onNavigateToRequest
                    ? () => onNavigateToRequest(scheduleItem.id)
                    : undefined
                }
                fl3xxTaskType={scheduleItem.fl3xx_task_type}
                fl3xxTripNumber={scheduleItem.fl3xx_trip_number}
                finalPrice={finalPrice}
                totalProfit={totalProfit}
              />
            }
          >
            <ScheduleItemContainer
              $left={`${Math.max(0, wholeItemLeftPositionPercentage)}%`}
              $right={`${Math.max(0, wholeItemRightPositionPercentage)}%`}
              onClick={(e) => {
                e.stopPropagation()
                setOpenItemsIdentification(itemIdentification)
              }}
            >
              <PositionedScheduleItem
                isMarketingLeg={isMarketingLeg}
                isReserved={isReserved}
                name={itemTitle}
                variant={variant}
                legType={scheduleItem.type}
                passengerCount={scheduleItem.passenger_count}
                index={index}
                offerStatus={offerStatus}
                marketingLegConversionOptions={marketingLegConversionOptions}
                contextMenuActions={contextMenuActions}
                isHidden={!isVisible}
                isFromFl3xx={scheduleItem.source === ScheduleSource.FL3XX}
                departureAirport={
                  getAirportCode(scheduleItem.departure_airport) ?? ''
                }
                arrivalAirport={
                  getAirportCode(scheduleItem.arrival_airport) ?? ''
                }
                marketingExtensionOptions={
                  isMarketingLeg
                    ? {
                        startPercentage: marketplaceExtensionStartPercentage,
                        width: marketplaceExtensionWidthPercentage,
                      }
                    : undefined
                }
              />
            </ScheduleItemContainer>
          </Tooltip>
        )
      })}
    </>
  )
}

const ScheduleItemContainer = styled.div<ScheduleItemContainerProps>`
  position: absolute;
  height: 70%;
  top: 8%;

  left: ${({ $left }) => $left};
  right: ${({ $right }) => $right};
`

const PositionedScheduleItem = styled(ScheduleItem)`
  height: 100%;
  position: relative;
`
