import { head, last, pipe } from 'ramda'
import Big from 'big.js'
import dayjs from 'dayjs'

import { getRoundingAmount } from '@shared/v2/utils/price.utils'

import { Leg } from '@shared/entities/leg.entity'
import { Aircraft } from '@shared/entities/aircraft.entity'
import { LegTypes } from '@shared/enums'
import { REQUIRED_LEG_TYPES } from '@shared/constants'
import { assertUnreachable } from '@shared/utils/assertUnreachable'
import { getLegsFinalPrice } from '@shared/utils/computationCalculator'
import { produce } from 'immer'
import { OtherCostUnits } from '../types/offer.types'

/**
 * @todo [Tech] Docs
 */
export function getLegsOfType<T extends Pick<Leg, 'type'>>(
  legs: T[],
  types: LegTypes[],
): T[] {
  return legs.filter((leg) => types.includes(leg.type))
}

/**
 * @returns All occupied legs from a given array of legs
 *
 * @example
 * const input = [
 *   { id: 1, type: 'occupied' },
 *   { id: 2, type: 'empty' },
 *   { id: 3: type: 'ferry' },
 *   { id: 4: type: 'removed' },
 *   { id: 5: type: 'outage' },
 * ]
 *
 * getEmptyLegs(input) // [{ id: 2, type: 'empty' }]
 */
export function getEmptyLegs<T extends Pick<Leg, 'type'>>(legs: T[]): T[] {
  return getLegsOfType(legs, [LegTypes.Empty])
}

/**
 * @returns All occupied legs from a given array of legs
 *
 * @example
 * const input = [
 *   { id: 1, type: 'occupied' },
 *   { id: 2, type: 'empty' },
 *   { id: 3: type: 'ferry' },
 *   { id: 4: type: 'removed' },
 *   { id: 5: type: 'outage' },
 * ]
 *
 * getOccupiedLegs(input) // [{ id: 1, type: 'occupied' }]
 */
export function getOccupiedLegs<T extends Pick<Leg, 'type'>>(legs: T[]): T[] {
  return getLegsOfType(legs, [LegTypes.Occupied])
}

/**
 * @returns All ferry legs from a given array of legs
 *
 * @example
 *
 * const input = [
 *   { id: 1, type: 'occupied' },
 *   { id: 2, type: 'empty' },
 *   { id: 3: type: 'ferry' },
 *   { id: 4: type: 'removed' },
 *   { id: 5: type: 'outage' },
 * ]
 *
 * getRemovedLegs(legs) // [{ id: 3: type: 'ferry' }]
 */
export function getFerryLegs<T extends Pick<Leg, 'type'>>(legs: T[]): T[] {
  return getLegsOfType(legs, [LegTypes.Ferry])
}

/**
 * @returns All outage legs from a given array of legs
 *
 * @example
 *
 * const input = [
 *   { id: 1, type: 'occupied' },
 *   { id: 2, type: 'empty' },
 *   { id: 3: type: 'ferry' },
 *   { id: 4: type: 'removed' },
 *   { id: 5: type: 'outage' },
 * ]
 *
 * getRemovedLegs(legs) // [{ id: 5: type: 'outage' }]
 */
export function getOutageLegs<T extends Pick<Leg, 'type'>>(legs: T[]): T[] {
  return getLegsOfType(legs, [LegTypes.Outage])
}

/**
 * @returns All removed legs from a given array of legs
 *
 * @example
 *
 * const input = [
 *   { id: 1, type: 'occupied' },
 *   { id: 2, type: 'empty' },
 *   { id: 3: type: 'ferry' },
 *   { id: 4: type: 'removed' },
 *   { id: 5: type: 'outage' },
 * ]
 *
 * getRemovedLegs(legs) // [{ id: 4, type: 'removed' }]
 */
export function getRemovedLegs<T extends Pick<Leg, 'type'>>(legs: T[]): T[] {
  return getLegsOfType(legs, [LegTypes.Removed])
}

/**
 * @returns Effective legs from a given array of legs
 *
 * Effective legs are all legs that given aircraft has to realize:
 * - Occupied legs
 * - Empty legs
 * - Ferry legs
 * - Outage legs
 *
 * Effective legs are not
 * - Removed legs
 *
 * @example
 * const input = [
 *   { id: 1, type: 'occupied' },
 *   { id: 2, type: 'empty' },
 *   { id: 3: type: 'ferry' },
 *   { id: 4: type: 'removed' },
 *   { id: 5: type: 'outage' },
 * ]
 *
 * getEffectiveLegs(input)
 *
 * // [
 * //  { id: 1, type: 'occupied' },
 * //  { id: 2, type: 'empty' },
 * //  { id: 3: type: 'ferry' },
 * //  { id: 5: type: 'outage' },
 * // ]
 */
export function getEffectiveLegs<T extends Pick<Leg, 'type'>>(legs: T[]): T[] {
  return getLegsOfType(legs, [
    LegTypes.Occupied,
    LegTypes.Empty,
    LegTypes.Ferry,
    LegTypes.Outage,
  ])
}

/**
 * @returns All required legs from a given array of legs
 *
 * Required legs are all effective legs that can't be optimized
 * - Occupied legs
 * - Ferry legs
 * - Outage legs
 *
 * Required legs are not
 * - Empty legs
 * - Removed legs
 */
export function getRequiredLegs<T extends Pick<Leg, 'type'>>(legs: T[]): T[] {
  return getLegsOfType(legs, REQUIRED_LEG_TYPES)
}

/**
 * @returns Given array of legs sorted by the departure date
 *
 * @example
 * const input = [
 *   { departure_date: '2022-05-01 10:00:00.000' },
 *   { departure_date: '2022-05-01 08:00:00.000' },
 *   { departure_date: '2022-05-01 09:00:00.000' },
 * ]
 *
 * getLegsSortedByDepartureDate(input)
 *
 * // [
 * //   { departure_date: '2022-05-01 08:00:00.000' }
 * //   { departure_date: '2022-05-01 09:00:00.000' }
 * //   { departure_date: '2022-05-01 10:00:00.000' }
 * // ]
 */
export function getLegsSortedByDepartureDate<
  T extends Pick<Leg, 'departure_date'>,
>(legs: T[]): T[] {
  return legs
    .concat()
    .sort((a, b) => dayjs(a.departure_date).diff(b.departure_date))
}

/**
 * @returns First departing required leg from a given array of legs
 *
 * @example
 * const input = [
 *   { type: 'empty', departure_date: '2022-05-04 10:00:00.000' },
 *   { type: 'occupied', departure_date: '2022-05-03 10:00:00.000' },
 *   { type: 'occupied', departure_date: '2022-05-02 10:00:00.000' },
 *   { type: 'empty', departure_date: '2022-05-01 10:00:00.000' },
 * ]
 *
 * getFirstRequiredLeg(input)
 *
 * // [{ type: 'occupied', departure_date: '2022-05-02 10:00:00.000' }]
 */
export function getFirstRequiredLeg<
  T extends Pick<Leg, 'departure_date' | 'type'>,
>(legs: T[]): T {
  return pipe<[T[]], T[], T[], T>(
    getLegsSortedByDepartureDate,
    getRequiredLegs,
    head,
  )(legs)
}

/**
 * @returns Last departing required leg from a given array of legs
 *
 * @example
 * const input = [
 *   { type: 'empty', departure_date: '2022-05-04 10:00:00.000' },
 *   { type: 'occupied', departure_date: '2022-05-03 10:00:00.000' },
 *   { type: 'occupied', departure_date: '2022-05-02 10:00:00.000' },
 *   { type: 'empty', departure_date: '2022-05-01 10:00:00.000' },
 * ]
 *
 * getFirstRequiredLeg(input)
 *
 * // [{ type: 'occupied', departure_date: '2022-05-03 10:00:00.000' }]
 */
export function getLastRequiredLeg<
  T extends Pick<Leg, 'departure_date' | 'type'>,
>(legs: T[]): T {
  return pipe<[T[]], T[], T[], T>(
    getLegsSortedByDepartureDate,
    getRequiredLegs,
    last,
  )(legs)
}

/**
 * @returns Sum of all distances from a given array of legs in nautical miles
 *
 * @example
 * const input = [
 *   { distance_in_nautical_miles: 2_300 },
 *   { distance_in_nautical_miles: 1_000 },
 *   { distance_in_nautical_miles: 250 },
 * ]
 *
 * getLegsDistanceInNauticalMiles(input) // 3550
 */
export function getLegsDistanceInNauticalMiles(
  legs: Pick<Leg, 'distance_in_nautical_miles'>[],
): number {
  return legs
    .reduce((acc, cur) => acc.add(cur.distance_in_nautical_miles), Big(0))
    .round()
    .toNumber()
}

/**
 * @returns Sum of all durations from a given array of legs in minutes
 *
 * @example
 * const input = [
 *   { duration_in_minutes: 120 },
 *   { duration_in_minutes: 50 },
 *   { duration_in_minutes: 60 },
 * ]
 *
 * getLegsDurationInMinutes(input) // 230
 */
export function getLegsDurationInMinutes(
  legs: Pick<Leg, 'duration_in_minutes'>[],
): number {
  return legs
    .reduce((acc, cur) => acc.add(cur.duration_in_minutes), Big(0))
    .round()
    .toNumber()
}

/**
 * @returns Required turnaround before leg of a given type based on a given
 * partial aircraft
 *
 * @todo [docs] add examples
 */
export function getLegTurnaroundInMinutes(
  type: LegTypes,
  partialAircraft: Pick<
    Aircraft,
    | 'turnaround_before_empty_leg_in_minutes'
    | 'turnaround_before_occupied_leg_in_minutes'
  >,
): number {
  switch (type) {
    case LegTypes.Empty:
      return partialAircraft.turnaround_before_empty_leg_in_minutes

    case LegTypes.Ferry:
    case LegTypes.Occupied:
      return partialAircraft.turnaround_before_occupied_leg_in_minutes

    case LegTypes.Outage:
      return 0

    case LegTypes.Removed:
      throw new Error(`Turnaround can't be calculated for leg type '${type}'`)

    default:
      assertUnreachable(type)
  }
}

/**
 * @returns Whether given departure date is in between departure date and
 * arrival date of a given partial leg
 *
 * @todo [docs] Add examples
 */
export function getDoesDateClashWithLeg(
  date: Date,
  partialLeg: Pick<Leg, 'departure_date' | 'arrival_date'>,
  includeEdges = true,
) {
  const edges = includeEdges ? '[]' : '()'

  return dayjs
    .utc(date)
    .isBetween(partialLeg.departure_date, partialLeg.arrival_date, null, edges)
}

/**
 * @returns Whether given partial legs juncture dates clash
 *
 * @todo [docs] add examples
 */
export function getAreTwoLegsClashing(
  partialLegA: Pick<Leg, 'departure_date' | 'arrival_date'>,
  partialLegB: Pick<Leg, 'departure_date' | 'arrival_date'>,
  includeEdges = true,
) {
  const doesDepartureClash = getDoesDateClashWithLeg(
    partialLegA.departure_date,
    partialLegB,
    includeEdges,
  )

  const doesArrivalClash = getDoesDateClashWithLeg(
    partialLegA.arrival_date,
    partialLegB,
    includeEdges,
  )

  return doesDepartureClash || doesArrivalClash
}

/**
 * @returns whether any leg in given array of sorted legs is clashing or
 * overlapping
 *
 * @todo [refactor] make more functional
 * @todo [docs] add examples
 */
export function getAreAnyLegsClashing(
  sortedLegs: Pick<Leg, 'departure_date' | 'arrival_date'>[],
): boolean {
  for (let i = 0, j = sortedLegs.length - 1; i < j; i++) {
    const currentLeg = sortedLegs[i]
    const nextLeg = sortedLegs[i + 1]

    if (getAreTwoLegsClashing(currentLeg, nextLeg)) {
      return true
    }
  }

  return false
}

/**
 * @returns Given leg with calculated departure date and arrival date (if either
 * one is missing)
 *
 * All input properties are copied to the returning value
 *
 * @throws if neither departure date and arrival date is present
 *
 * @example
 * const leg = {
 *   departure_date: '2022-10-01T00:00:00Z',
 *   duration_in_minutes: 60,
 *   passenger_count: 2,
 * }
 *
 * getLegMissingDate(leg)
 * // {
 * //   departure_date: '2022-10-01T00:00:00Z',
 * //   arrival_date: '2022-10-01T01:00:00Z',
 * //   duration_in_minutes: 60,
 * //   passenger_count: 2,
 * // }
 */
export function getLegMissingDate<
  T extends Pick<Leg, 'duration_in_minutes'> &
    Partial<Pick<Leg, 'departure_date' | 'arrival_date'>>,
>(leg: T): T & Required<Pick<Leg, 'departure_date' | 'arrival_date'>> {
  if (!leg.departure_date && !leg.arrival_date) {
    throw new Error(
      `Either 'departure_date' or 'arrival_date' must be present in order to calculate leg missing date but instead got '${JSON.stringify(
        leg,
      )}'`,
    )
  }

  return {
    ...leg,

    arrival_date:
      leg.arrival_date ??
      dayjs(leg.departure_date)
        .add(leg.duration_in_minutes, 'minutes')
        .toDate(),

    departure_date:
      leg.departure_date ??
      dayjs(leg.arrival_date)
        .subtract(leg.duration_in_minutes, 'minutes')
        .toDate(),
  }
}

/**
 * @todo [docs] add docs
 * @todo [refactor] revisit & refactor
 */
export function getLegsWithRounding<
  T extends Pick<
    Leg,
    | 'type'
    | 'variable_cost'
    | 'profit'
    | 'airport_fee'
    | 'handling_fee'
    | 'catering_fee'
    | 'arrival_fee'
    | 'departure_fee'
    | 'passenger_count'
    | 'other_costs'
    | 'remove_leg_id'
    | 'remove_leg'
    | 'duration_in_minutes'
  >,
>(
  legs: T[],
  aircraft: Pick<Aircraft, 'rounding_multiple' | 'rounding_type'>,
): T[] {
  const roundAmount = getRoundingAmount(
    getLegsFinalPrice(legs).toNumber(),
    aircraft.rounding_type,
    aircraft.rounding_multiple,
  )

  const requiredLegsCount = getRequiredLegs(legs).length

  if (requiredLegsCount === 0) {
    return legs
  }

  const increase = Big(roundAmount)
    .div(requiredLegsCount)
    .round(0, Big.roundDown)
    .toNumber()

  const reminder = Big(roundAmount).mod(requiredLegsCount).toNumber()

  let isRemainderAdded = false

  return legs.map(
    produce((optimizedLeg) => {
      if (REQUIRED_LEG_TYPES.includes(optimizedLeg.type)) {
        optimizedLeg.profit += increase

        if (!isRemainderAdded) {
          optimizedLeg.profit += reminder

          isRemainderAdded = true
        }
      }
    }),
  )
}

export function getLegsWithDailyCrewRate<
  T extends Pick<
    Leg,
    | 'departure_date'
    | 'arrival_date'
    | 'other_costs'
    | 'type'
    | 'arrival_airport_id'
    | 'departure_airport_id'
  > & { is_relevant?: boolean },
>(
  legs: T[],
  aircraft: Pick<Aircraft, 'daily_crew_rate' | 'base_airport_id'>,
): T[] {
  let days = 0
  let dates = []

  let precedingLegIndex: number | null = null

  for (let i = 0, j = legs.length; i < j; i++) {
    const currentLeg = legs[i]

    if (currentLeg.type === LegTypes.Removed) {
      const nextIndex = i + 1

      if (nextIndex === j) {
        continue
      }

      i = nextIndex

      continue
    }

    if (currentLeg.is_relevant !== true) {
      precedingLegIndex = i

      continue
    }

    const right = dayjs(currentLeg.departure_date).startOf('day')

    const date = right.format('DD/MM/YYYY')

    if (precedingLegIndex === null) {
      precedingLegIndex = i

      dates = dates.concat(date)

      days += 1

      continue
    }

    if (currentLeg.departure_airport_id === aircraft.base_airport_id) {
      precedingLegIndex = i

      if (!dates.includes(date)) {
        dates = dates.concat(date)

        days += 1
      }

      continue
    }

    const precedingLeg = legs[precedingLegIndex]

    const left = dayjs(precedingLeg.arrival_date).startOf('day')

    const diff = right.diff(left, 'days')

    precedingLegIndex = i

    if (dates.includes(date)) {
      continue
    }

    dates = dates.concat(date)

    days += Math.max(diff, 1)
  }

  let dailyCrewRateAllocated = false

  const dailyCrewRate = Big(aircraft.daily_crew_rate ?? 0)
    .times(days)
    .toNumber()

  if (dailyCrewRate === 0) {
    return legs
  }

  return legs.map((leg) => {
    if (
      leg.is_relevant &&
      !dailyCrewRateAllocated &&
      REQUIRED_LEG_TYPES.includes(leg.type)
    ) {
      let nextOtherCosts = leg.other_costs ?? []

      nextOtherCosts = nextOtherCosts.concat({
        label: 'Daily crew rate',
        unit: OtherCostUnits.FlatFee,
        value: dailyCrewRate,
      })

      dailyCrewRateAllocated = true

      return {
        ...leg,
        other_costs: nextOtherCosts,
      }
    }

    return leg
  })
}

type DayUseHotelT = Pick<
  Leg,
  | 'departure_date'
  | 'arrival_date'
  | 'other_costs'
  | 'type'
  | 'arrival_airport_id'
  | 'departure_airport_id'
> & { is_relevant?: boolean }

const isDayUseHotelRequired = <T extends DayUseHotelT>(leg1: T, leg2: T) => {
  const departureTime = dayjs.utc(leg2.departure_date)
  const arrivalTime = dayjs.utc(leg1.arrival_date)
  const timeDifference = departureTime.diff(arrivalTime, 'hour', true)
  return timeDifference >= 4 && timeDifference <= 8
}

const addDayUseHotelCost = <T extends DayUseHotelT>(
  leg: T,
  aircraft: Pick<Aircraft, 'day_use_hotel' | 'base_airport_id'>,
) => {
  return {
    ...leg,
    other_costs: [
      ...leg.other_costs,
      {
        label: 'Day-use hotel',
        unit: OtherCostUnits.FlatFee,
        // must default to 0 (not NULL) or the aircraft will be scrambled from Search
        value: aircraft.day_use_hotel || 0,
      },
    ],
  }
}

const removeDayUseHotelCost = <T extends DayUseHotelT>(leg: T) => {
  return {
    ...leg,
    other_costs: leg.other_costs.filter(
      (cost) => cost.label !== 'Day-use hotel',
    ),
  }
}

export function getDayUseHotelRate<T extends DayUseHotelT>(
  legs: T[],
  aircraft: Pick<Aircraft, 'day_use_hotel' | 'base_airport_id'>,
): T[] {
  return legs.map((currentLeg, i) => {
    if (
      !currentLeg.is_relevant ||
      currentLeg.arrival_airport_id === aircraft.base_airport_id
    ) {
      return currentLeg
    }

    const nextLeg = legs[i + 1]

    if (nextLeg && isDayUseHotelRequired(currentLeg, nextLeg)) {
      return addDayUseHotelCost(currentLeg, aircraft)
    }

    // Check whether there is an empty timeslot between this and previous offer
    const firstNonRelevantOccupiedLeg = legs.find(
      (leg) => !leg.is_relevant && leg.type === LegTypes.Occupied,
    )

    const firstRelevantLeg = legs.find((leg) => leg.is_relevant)

    if (
      firstNonRelevantOccupiedLeg &&
      firstRelevantLeg &&
      firstRelevantLeg.departure_airport_id ===
        currentLeg.departure_airport_id &&
      firstNonRelevantOccupiedLeg.arrival_airport_id ===
        firstRelevantLeg.departure_airport_id
    ) {
      if (isDayUseHotelRequired(firstNonRelevantOccupiedLeg, currentLeg)) {
        return addDayUseHotelCost(currentLeg, aircraft)
      }
    }

    if (currentLeg.other_costs.find((cost) => cost.label === 'Day-use hotel')) {
      return removeDayUseHotelCost(currentLeg)
    }

    return currentLeg
  })
}
