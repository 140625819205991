import React, { useState } from 'react'
import styled from 'styled-components'
import { castDraft, produce } from 'immer'
import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'

import Button from '@app/components/atoms/Button/Button'

import TextField, {
  TextFieldPriceFormatter,
} from '@app/components/atoms/TextField/TextField'

import FeesManagerField from '@app/components/molecules/FeesManager/FeesManagerField'
import { assertNumberFinite } from '@shared/v2/utils/type.utils'

type Fee = { label: string; value: number | null }

type FeesManagerProps<T extends Fee> = {
  fees: T[]
  readonly?: boolean
  onChange: (nextFees: T[]) => void | Promise<void>
  getDefaultFee: (label: string, value: number) => T
}

const DEFAULT_LABEL = ''
const DEFAULT_VALUE = '0'

const FeesManager = <T extends Fee>({
  fees,
  onChange,
  getDefaultFee,
  readonly = false,
}: FeesManagerProps<T>) => {
  const { t } = useTranslation()

  const [label, setLabel] = useState(DEFAULT_LABEL)
  const [value, setValue] = useState(DEFAULT_VALUE)

  const onLabelChange = (index: number) => (nextLabel: string) => {
    onChange(
      produce(fees, (draft) => {
        draft[index].label = nextLabel
      }),
    )
  }

  const onValueChange = (index: number) => (nextValue: string) => {
    onChange(
      produce(fees, (draft) => {
        draft[index].value = Number(nextValue)
      }),
    )
  }

  const onFieldDelete = (index: number) => () => {
    onChange(
      produce(fees, (draft) => {
        draft.splice(index, 1)
      }),
    )
  }

  const onFieldAdd = () => {
    const nextValue = Number(value)

    assertNumberFinite(nextValue)

    onChange(
      produce(fees, (draft) => {
        // @see https://github.com/immerjs/immer/issues/470
        draft.push(castDraft(getDefaultFee(label, nextValue)))
      }),
    )

    setLabel(DEFAULT_LABEL)
    setValue(DEFAULT_VALUE)
  }

  const isDuplicateLabel = fees.some(
    (fee) => fee.label.trim().toLowerCase() === label.trim().toLowerCase(),
  )

  return (
    <div>
      {fees.map((fee, index) => (
        <FeesManagerField
          key={fee.label}
          label={fee.label}
          value={Number.isFinite(fee.value) ? String(fee.value) : ''}
          readonly={readonly}
          onLabelChange={onLabelChange(index)}
          onValueChange={onValueChange(index)}
          onDelete={onFieldDelete(index)}
        />
      ))}

      {!readonly && (
        <Box display="flex" justifyContent="space-between" mt={1}>
          <LabelTextField
            value={label}
            onChange={(event) => setLabel(event.target.value)}
          />
          <UnitTextField
            value={value}
            inputComponent={TextFieldPriceFormatter}
            onChange={(event) => setValue(event.target.value)}
          />
          <AddFeeButton
            size="large"
            disabled={!label || !value || isDuplicateLabel}
            onClick={onFieldAdd}
          >
            {t('molecules.FeesManager.addButtonLabel')}
          </AddFeeButton>
        </Box>
      )}
    </div>
  )
}

const AddFeeButton = styled(Button)`
  margin-left: 1rem;
  width: 5rem;
`

const LabelTextField = styled(TextField)`
  padding-right: 1rem;
  flex: 1;
`

const UnitTextField = styled(TextField)`
  width: 5rem;

  & input {
    text-align: right;
  }
`

export default FeesManager
