import { useTranslation } from 'react-i18next'
import { __, concat, curry, map, pipe } from 'ramda'
import * as yup from 'yup'
import dayjs from 'dayjs'

import { getUTCFromTimezoneAwareDate } from '@app/utils/dateUtils'
import { getPrecedingLegValues } from '@app/components/organisms/LegEditorForm/LegEditorForm.utils'
import { LegFormData } from '@app/components/organisms/LegEditorForm/LegEditorForm'

import { getScheduleWithRemovedLegsOmitted } from '@shared/v2/utils/schedule.utils'
import { castLegJunctureToDates } from '@shared/v2/utils/type.utils'

import { DisplayTimeTypes, LegTypes } from '@shared/enums'
import { ScheduleDetailDto } from '@shared/dto/schedule.dto'
import { BaseLegDetailDto } from '@shared/dto/requests.dto'

import {
  getDoesDateClashWithLeg,
  getLegsSortedByDepartureDate,
} from '@shared/v2/utils/leg.utils'

export const LEGS_DEPARTURE_AIRPORT_CONTINUITY_ERROR_TYPE =
  'legDepartureAirportContinuity'

export const LEGS_ARRIVAL_AIRPORT_CONTINUITY_ERROR_TYPE =
  'legArrivalAirportContinuity'

type LegEditorValidationSchedule = {
  departure_airport_id: number
  arrival_airport_id: number
  departure_date: Date
  arrival_date?: Date
}

type LegEditorMinimalValue = {
  departureAirport: { id: number; timezone: string }
  arrivalAirport: { id: number; timezone: string }
  departureDate: Date
  departureTime: Date
}

const getIsLegEditorMinimalValue = (
  value: unknown,
): value is LegEditorMinimalValue => {
  const leg = value as LegEditorMinimalValue

  return Boolean(
    leg.departureAirport &&
      leg.arrivalAirport &&
      leg.departureDate &&
      leg.departureTime,
  )
}

const getCombinedValidationSchedule = (
  schedule: ScheduleDetailDto[],
  removedLegs: BaseLegDetailDto[],
  legEditorLegs: LegFormData[],
  timeDisplay: DisplayTimeTypes,
) => {
  return pipe<
    [ScheduleDetailDto[]],
    Required<LegEditorValidationSchedule>[],
    LegEditorValidationSchedule[],
    LegEditorValidationSchedule[],
    LegEditorValidationSchedule[]
  >(
    curry(getScheduleWithRemovedLegsOmitted)(__, removedLegs),
    map(castLegJunctureToDates),
    concat(
      legEditorLegs.map((leg) =>
        castLegEditorMinimalValueToValidationSchedule(leg, timeDisplay),
      ),
    ),
    getLegsSortedByDepartureDate,
  )(schedule)
}

const castLegEditorMinimalValueToValidationSchedule = (
  input: LegFormData,
  timeDisplay: DisplayTimeTypes,
): LegEditorValidationSchedule => {
  /**
   * @todo Handle scenario when 'departureDate' is missing
   */
  if (
    !input.departureDate ||
    !input.departureTime ||
    !input.departureAirport ||
    !input.arrivalAirport
  ) {
    throw new Error(
      `Leg editor value must contain all required properties but instead got '${JSON.stringify(
        input,
      )}'`,
    )
  }

  return {
    departure_airport_id: input.departureAirport.id,
    arrival_airport_id: input.arrivalAirport.id,

    arrival_date:
      input.arrivalDate && input.arrivalTime
        ? dayjs
            .utc(
              getUTCFromTimezoneAwareDate(
                input.arrivalDate,
                input.arrivalTime,
                input.arrivalAirport.timezone,
                timeDisplay,
              ),
            )
            .toDate()
        : undefined,

    departure_date: dayjs
      .utc(
        getUTCFromTimezoneAwareDate(
          input.departureDate,
          input.departureTime,
          input.departureAirport.timezone,
          timeDisplay,
        ),
      )
      .toDate(),
  }
}

const testAtLeastOneDateRequired: yup.TestFunction = (value, { parent }) =>
  Boolean(parent.arrivalDate && parent.arrivalTime) ||
  Boolean(parent.departureDate && parent.departureTime)

const testArrivalAirportAndDepartureAirportAreDifferent: yup.TestFunction = (
  value,
  { parent },
) => {
  if (!parent.arrivalAirport || !parent.departureAirport) {
    return true
  }

  return parent.arrivalAirport.id !== parent.departureAirport.id
}

const getTestLegDepartureAirportContinuity: (
  schedule: ScheduleDetailDto[],
  removedLegs: BaseLegDetailDto[],
  timeDisplay: DisplayTimeTypes,
) => yup.TestFunction =
  (schedule, removedLegs, timeDisplay) => (value, context) => {
    if (!getIsLegEditorMinimalValue(value)) {
      return true
    }

    const potentialSchedule = getCombinedValidationSchedule(
      schedule,
      removedLegs,
      context.parent,
      timeDisplay,
    )

    const departureDateTime = getUTCFromTimezoneAwareDate(
      value.departureDate,
      value.departureTime,
      value.departureAirport.timezone,
      timeDisplay,
    )

    /**
     * @todo Using 'departure_date' is not really safe - find better solution
     */
    const index = potentialSchedule.findIndex((potentialScheduleItem) =>
      dayjs.utc(potentialScheduleItem.departure_date).isSame(departureDateTime),
    )

    const isFirstItem = index === 0

    if (isFirstItem) {
      return true
    }

    return (
      value.departureAirport.id ===
      potentialSchedule[index - 1].arrival_airport_id
    )
  }

const getTestLegArrivalAirportContinuity: (
  schedule: ScheduleDetailDto[],
  removedLegs: BaseLegDetailDto[],
  timeDisplay: DisplayTimeTypes,
) => yup.TestFunction =
  (schedule, removedLegs, timeDisplay) => (value, context) => {
    if (!getIsLegEditorMinimalValue(value)) {
      return true
    }

    const potentialSchedule = getCombinedValidationSchedule(
      schedule,
      removedLegs,
      context.parent,
      timeDisplay,
    )

    const departureDateTime = getUTCFromTimezoneAwareDate(
      value.departureDate,
      value.departureTime,
      value.departureAirport.timezone,
      timeDisplay,
    )

    /**
     * @todo Using 'departure_date' is not really safe - find better solution
     */
    const index = potentialSchedule.findIndex((potentialScheduleItem) =>
      dayjs.utc(potentialScheduleItem.departure_date).isSame(departureDateTime),
    )

    const isLastItem = index === potentialSchedule.length - 1

    if (isLastItem) {
      return true
    }

    return (
      value.arrivalAirport.id ===
      potentialSchedule[index + 1].departure_airport_id
    )
  }

const getTestIsNotOverlapping: (
  timeDisplay: DisplayTimeTypes,
) => yup.TestFunction<Date | null | undefined> =
  (timeDisplay) => (value, context) => {
    const precedingLegValues = getPrecedingLegValues(context)

    if (
      !precedingLegValues?.arrivalDate ||
      !precedingLegValues?.arrivalTime ||
      !precedingLegValues?.arrivalAirport
    ) {
      return true
    }

    if (
      !context.parent.departureTime ||
      !context.parent.departureDate ||
      !context.parent.departureAirport
    ) {
      return true
    }

    const precedingArrivalDateTime = getUTCFromTimezoneAwareDate(
      precedingLegValues.arrivalDate,
      precedingLegValues.arrivalTime,
      precedingLegValues.arrivalAirport.timezone,
      timeDisplay,
    )

    const departureDateTime = getUTCFromTimezoneAwareDate(
      context.parent.departureDate,
      context.parent.departureTime,
      context.parent.departureAirport.timezone,
      timeDisplay,
    )

    return dayjs.utc(departureDateTime).isAfter(precedingArrivalDateTime)
  }

const getTestIsDepartureClashingWithSchedule: (
  schedule: ScheduleDetailDto[],
  removedLegs: BaseLegDetailDto[],
  timeDisplay: DisplayTimeTypes,
) => yup.TestFunction<Date | null | undefined> =
  (schedule, removedLegs, timeDisplay) => (value, context) => {
    if (
      !context.parent.departureTime ||
      !context.parent.departureDate ||
      !context.parent.departureAirport
    ) {
      return true
    }

    const departureDateTime = getUTCFromTimezoneAwareDate(
      context.parent.departureDate,
      context.parent.departureTime,
      context.parent.departureAirport.timezone,
      timeDisplay,
    )

    const validationSchedule = getScheduleWithRemovedLegsOmitted(
      schedule,
      removedLegs,
    )

    return validationSchedule.every((scheduleItem) => {
      return !getDoesDateClashWithLeg(
        dayjs.utc(departureDateTime).toDate(),
        castLegJunctureToDates(scheduleItem),
      )
    })
  }

const getTestIsArrivalClashingWithSchedule: (
  schedule: ScheduleDetailDto[],
  removedLegs: BaseLegDetailDto[],
  timeDisplay: DisplayTimeTypes,
) => yup.TestFunction<Date | null | undefined> =
  (schedule, removedLegs, timeDisplay) => (value, context) => {
    if (
      !context.parent.arrivalTime ||
      !context.parent.arrivalDate ||
      !context.parent.arrivalAirport
    ) {
      return true
    }

    const arrivalDateTime = getUTCFromTimezoneAwareDate(
      context.parent.arrivalDate,
      context.parent.arrivalTime,
      context.parent.arrivalAirport.timezone,
      timeDisplay,
    )

    const validationSchedule = getScheduleWithRemovedLegsOmitted(
      schedule,
      removedLegs,
    )

    return validationSchedule.every((scheduleItem) => {
      return !getDoesDateClashWithLeg(
        dayjs.utc(arrivalDateTime).toDate(),
        castLegJunctureToDates(scheduleItem),
      )
    })
  }

const useLegEditorValidationSchema = ({
  schedule,
  removedLegs,
  timeDisplay,
}: {
  schedule: ScheduleDetailDto[]
  removedLegs: BaseLegDetailDto[]
  timeDisplay: DisplayTimeTypes
}) => {
  const { t } = useTranslation()

  return yup.object().shape({
    legs: yup
      .array()
      .transform((legs: BaseLegDetailDto[]) =>
        legs.filter((leg) => leg.type !== LegTypes.Removed),
      )
      .of(
        yup
          .object()
          .shape({
            type: yup.string().oneOf(Object.values(LegTypes)).required(),
            departureDate: yup
              .date()
              .optional()
              .nullable()
              .test(
                'isNotOverlapping',
                t('organisms.LegEditorForm.isLegOverlapping'),
                getTestIsNotOverlapping(timeDisplay),
              )
              .test(
                'atLeastOneDateRequired',
                t('organisms.LegEditorForm.atLeastOneDateRequired'),
                testAtLeastOneDateRequired,
              )
              .test(
                'departureClashingWithSchedule',
                t('organisms.LegEditorForm.departureClashingWithSchedule'),
                getTestIsDepartureClashingWithSchedule(
                  schedule,
                  removedLegs,
                  timeDisplay,
                ),
              ),
            departureTime: yup
              .date()
              .optional()
              .nullable()
              .test(
                'isNotOverlapping',
                t('organisms.LegEditorForm.isLegOverlapping'),
                getTestIsNotOverlapping(timeDisplay),
              )
              .test(
                'atLeastOneDateRequired',
                t('organisms.LegEditorForm.atLeastOneDateRequired'),
                testAtLeastOneDateRequired,
              )
              .test(
                'departureClashingWithSchedule',
                t('organisms.LegEditorForm.departureClashingWithSchedule'),
                getTestIsDepartureClashingWithSchedule(
                  schedule,
                  removedLegs,
                  timeDisplay,
                ),
              ),
            departureAirport: yup
              .object()
              .typeError(t('organisms.LegEditorForm.departureAirportRequired'))
              .required(t('organisms.LegEditorForm.departureAirportRequired')),
            arrivalDate: yup
              .date()
              .optional()
              .nullable()
              .test(
                'atLeastOneDateRequired',
                t('organisms.LegEditorForm.atLeastOneDateRequired'),
                testAtLeastOneDateRequired,
              )
              .test(
                'arrivalClashingWithSchedule',
                t('organisms.LegEditorForm.arrivalClashingWithSchedule'),
                getTestIsArrivalClashingWithSchedule(
                  schedule,
                  removedLegs,
                  timeDisplay,
                ),
              ),
            arrivalTime: yup
              .date()
              .optional()
              .nullable()
              .test(
                'atLeastOneDateRequired',
                t('organisms.LegEditorForm.atLeastOneDateRequired'),
                testAtLeastOneDateRequired,
              )
              .test(
                'arrivalClashingWithSchedule',
                t('organisms.LegEditorForm.arrivalClashingWithSchedule'),
                getTestIsArrivalClashingWithSchedule(
                  schedule,
                  removedLegs,
                  timeDisplay,
                ),
              ),
            arrivalAirport: yup
              .object()
              .typeError(t('organisms.LegEditorForm.arrivalAirportRequired'))
              .required(t('organisms.LegEditorForm.arrivalAirportRequired'))
              .test(
                'arrivalSameAsDeparture',
                t('organisms.LegEditorForm.arrivalSameAsDeparture'),
                testArrivalAirportAndDepartureAirportAreDifferent,
              ),
            passengerCount: yup
              .number()
              .min(0, t('organisms.LegEditorForm.passengerCountPositiveNumber'))
              .typeError(
                t('organisms.CreateRequestForm.passengerCountRequired'),
              ),
            aircraft: yup.object(),
            extras: yup.object().nullable(),
          })
          .test(
            LEGS_DEPARTURE_AIRPORT_CONTINUITY_ERROR_TYPE,
            t('organisms.LegEditorForm.gapInLegDepartureAirportContinuity'),
            getTestLegDepartureAirportContinuity(
              schedule,
              removedLegs,
              timeDisplay,
            ),
          )
          .test(
            LEGS_ARRIVAL_AIRPORT_CONTINUITY_ERROR_TYPE,
            t('organisms.LegEditorForm.gapInLegArrivalAirportContinuity'),
            getTestLegArrivalAirportContinuity(
              schedule,
              removedLegs,
              timeDisplay,
            ),
          )
          .required(),
      )
      .required(),
  })
}

export default useLegEditorValidationSchema
