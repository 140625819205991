import { useQuery } from '@tanstack/react-query'

import { api } from '@app/utils/api/api'

import {
  SimilarRequestItem,
  SimilarRequestsSectionProps,
} from '@app/components/organisms/SimilarRequestsSection/SimilarRequestsSection'

export const useSimilarRequestSectionProps = (
  requestId: number,
): SimilarRequestsSectionProps => {
  const {
    data: axiosResponse,
    isLoading,
    isError,
  } = useQuery(['similar-requests', requestId], () =>
    api.fetchSimilarRequests(requestId),
  )

  const data: SimilarRequestItem[] =
    axiosResponse?.data.data.map((item) => {
      return {
        client_name: item.contact_person?.name,
        company_name: item.client?.company_name,
        profit: item.trip_total_profit,
        total_price: item.trip_final_price,
        trip_id: item.trip_id,
        aircraft_registration_code: item.trip_aircraft
          ? item.trip_aircraft.registration_code
          : 'DELETED',
        offer_status: item.trip_offer_status,
        id: item.id
      }
    }) ?? []

  return { isLoading, isError, data }
}
