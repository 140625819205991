import dayjs from 'dayjs'

import { Offer } from '@shared/entities/offer.entity'
import { LegTypes, OfferFlags, OfferStatuses } from '@shared/enums'
import { Request } from '@shared/entities/request.entity'
import { getFirstRequiredLeg } from '@shared/v2/utils/leg.utils'

import {
  getOfferFinalPriceForClient,
  getOfferTotalProfit,
} from '@shared/utils/computationCalculator'
import { TripInfo } from '@shared/dto/requests.dto'

export const getDisplayOffer = <T extends Pick<Offer, 'status' | 'updated_at'>>(
  offers: T[],
): T => {
  const [lastBookedOffer] = offers
    .filter((offer) => offer.status === OfferStatuses.Booked)
    .sort((a, b) => (dayjs(a.updated_at).isAfter(b.updated_at) ? -1 : 1))

  if (lastBookedOffer) {
    return lastBookedOffer
  }

  const [lastUpdatedOffer] = offers
    .concat()
    .sort((a, b) => (dayjs(a.updated_at).isAfter(b.updated_at) ? -1 : 1))

  if (lastUpdatedOffer.status === OfferStatuses.Quoted) {
    return lastUpdatedOffer
  }

  const offerStatusWeights = {
    [OfferStatuses.New]: 0,
    [OfferStatuses.Unhandled]: 1,
    [OfferStatuses.Declined]: 2,
    [OfferStatuses.Draft]: 3,
    [OfferStatuses.Rejected]: 4,
    [OfferStatuses.Quoted]: 5,
    [OfferStatuses.Cancelled]: 6,
    [OfferStatuses.BookedCancelled]: 7,
    [OfferStatuses.Booked]: 8,
  }

  const defaultOfferStatusWeight = -1

  const [displayOffer] = offers.concat().sort((a, b) => {
    if (a.status === b.status) {
      return dayjs(a.updated_at).isAfter(b.updated_at) ? -1 : 1
    }

    const statusA = offerStatusWeights[a.status] ?? defaultOfferStatusWeight
    const statusB = offerStatusWeights[b.status] ?? defaultOfferStatusWeight

    return statusA > statusB ? -1 : 1
  })

  return displayOffer
}

export const getRequestTripInfo = (
  offers: Offer[],
): Pick<
  Request,
  | 'trip_departure_airport_id'
  | 'trip_arrival_airport_id'
  | 'trip_departure_date'
  | 'trip_aircraft_id'
  | 'trip_offer_status'
  | 'trip_final_price'
  | 'trip_total_profit'
  | 'trip_info'
> => {
  const displayOffer = getDisplayOffer(offers)

  const firstRequiredLeg =
    getFirstRequiredLeg(displayOffer.legs) ?? displayOffer.legs[0]

  const relevantLegs =
    displayOffer.status === OfferStatuses.BookedCancelled
      ? displayOffer.cancellation_offer.legs.filter(
          (leg) => leg.type === LegTypes.Removed && leg.passenger_count,
        )
      : displayOffer.legs.filter((leg) => leg.type === LegTypes.Occupied)

  const tripInfo: TripInfo[] = relevantLegs
    .sort((a, b) => dayjs(a.departure_date).diff(b.departure_date))
    .map((leg) => ({
      leg_departure_date: leg.departure_date,
      leg_arrival_date: leg.arrival_date,
      leg_departure_airport_icao: leg.departure_airport.icao_code,
      leg_departure_airport_iata: leg.departure_airport.iata_code,
      leg_departure_airport_timezone: leg.departure_airport.timezone,
      leg_arrival_airport_icao: leg.arrival_airport.icao_code,
      leg_arrival_airport_iata: leg.arrival_airport.iata_code,
      leg_arrival_airport_timezone: leg.arrival_airport.timezone,
      leg_passenger_count: leg.passenger_count,

      leg_departure_airport_country: leg.departure_airport.country,
      leg_departure_airport_city: leg.departure_airport.city,
      leg_departure_airport_runways: leg.departure_airport.extras.runways,

      leg_departure_airport_timezone_offset_in_minutes:
        leg.departure_airport.timezone_offset_in_minutes,

      leg_arrival_airport_country: leg.arrival_airport.country,
      leg_arrival_airport_city: leg.arrival_airport.city,
      leg_arrival_airport_runways: leg.arrival_airport.extras.runways,

      leg_arrival_airport_timezone_offset_in_minutes:
        leg.arrival_airport.timezone_offset_in_minutes,
    }))

  return {
    trip_info: tripInfo,
    trip_offer_status: displayOffer.status,
    trip_aircraft_id: displayOffer.aircraft_id,
    trip_final_price: getOfferFinalPriceForClient(displayOffer).toNumber(),
    trip_total_profit: getOfferTotalProfit(displayOffer).toNumber(),
    trip_departure_date: firstRequiredLeg?.departure_date ?? null,
    trip_departure_airport_id: firstRequiredLeg?.departure_airport_id ?? null,
    trip_arrival_airport_id: firstRequiredLeg?.arrival_airport_id ?? null,
  }
}

export const getRequestFlags = (offers: Offer[]): OfferFlags[] => {
  return Array.from(new Set(offers.map((offer) => offer.flag).filter(Boolean)))
}
