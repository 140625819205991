import { UserRole } from '@shared/entities/user_role.entity'

/**
 * @returns user role for a given operator in the given list of user roles
 *
 * @throws if user role for given operator doesn't exist in the given list
 * of user roles
 *
 * @example
 *
 * const userRoles = [
 *   { operator_id: 1, role: 'operator' },
 *   { operator_id: 2, role: 'manager' },
 * ]
 *
 * getUserRoleForOperator(userRoles, 1) // { operator_id: 1, role: 'operator' }
 *
 * getUserRoleForOperator(userRoles, 3) // Throws Error("user role for operator '3' doesn't exist")
 */
export function getUserRoleForOperator<T extends Pick<UserRole, 'operator_id'>>(
  userRoles: T[],
  operatorId: number,
): T {
  const userRole = userRoles.find(
    (userRole) => userRole.operator_id === operatorId,
  )

  if (!userRole) {
    throw new Error(`user role for operator '${operatorId}' doesn't exist`)
  }

  return userRole
}
