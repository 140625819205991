import { ClientDetailDto } from '@shared/dto/clients.dto'
import { ClientFormData } from '@app/components/organisms/ClientForm/ClientForm'
import { PostClientDto } from '@app/utils/api/types'

export const transformClientToFormData = (
  client: ClientDetailDto | null,
): Partial<ClientFormData> => {
  return {
    companyName: client?.company_name ?? '',
    street: client?.street ?? '',
    city: client?.city ?? '',
    zipCode: client?.zip_code ?? '',
    country: client?.country ?? null,
  }
}

export const transformFormDataToPartialClientDetailDto = (
  formValues: ClientFormData,
): Omit<PostClientDto, 'operator_id' | 'cancellation_fee_text'> => {
  return {
    company_name: formValues.companyName,
    street: formValues.street || null,
    city: formValues.city || null,
    zip_code: formValues.zipCode || null,
    country_id: formValues.country?.id || null,
  }
}
