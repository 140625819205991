import React, { useMemo } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import duration from 'dayjs/plugin/duration'
import format from 'number-format.js'
import { useTranslation } from 'react-i18next'

import FlightIcon from '@material-ui/icons/Flight'
import FlightOutlinedIcon from '@material-ui/icons/FlightOutlined'
import ConstructionIcon from '@material-ui/icons/Build'

import Typography from '@app/components/atoms/Typography/Typography'
import Price from '@app/components/atoms/Price/Price'

import { LegTypes } from '@shared/enums'
import { DEFAULT_NUMBER_FORMAT } from '@shared/constants'
import { useGetFormattedDuration } from '@app/hooks/useGetFormattedDuration'
import { useDateAndTimeFormats } from '@app/hooks/useDateAndTimeFormats'

dayjs.extend(utc)
dayjs.extend(duration)

interface LinkProps {
  $disabled: boolean
}

interface ScheduleTooltipContentProps {
  legType: LegTypes
  passengerCount: number
  departureAirport: string
  arrivalAirport: string
  departureDate: string | Date
  arrivalDate: string | Date
  flightTimeInMinutes?: number
  distanceInNauticalMiles?: number
  profit?: number
  price?: number
  tripId?: string
  onTripIdClick?: () => void
  fl3xxTaskType?: string
  fl3xxTripNumber?: number
  finalPrice?: number
  totalProfit?: number
}

const ScheduleTooltipContent = ({
  legType,
  passengerCount,
  departureAirport,
  arrivalAirport,
  departureDate,
  arrivalDate,
  flightTimeInMinutes = 0,
  distanceInNauticalMiles,
  profit,
  price,
  tripId,
  onTripIdClick,
  fl3xxTaskType,
  fl3xxTripNumber,
  finalPrice,
  totalProfit,
}: ScheduleTooltipContentProps) => {
  const { t } = useTranslation()
  const { timeFormat, dateFormat } = useDateAndTimeFormats()

  const getFormattedDuration = useGetFormattedDuration()

  const formattedDuration = getFormattedDuration(flightTimeInMinutes)

  const Icon = useMemo(() => {
    if (legType === LegTypes.Outage) {
      return StyledConstructionIcon
    }

    if (legType === LegTypes.Occupied) {
      return StyledFlightIcon
    }

    return StyledOutlinedFlightIcon
  }, [legType])

  return (
    <>
      <BasicTripInfo>
        <Departure>
          <Typography variant="title">
            {dayjs.utc(departureDate).format(dateFormat)}
          </Typography>
          <Typography variant="title">
            {dayjs.utc(departureDate).format(timeFormat)}
          </Typography>
          <Typography variant="content">{departureAirport}</Typography>
        </Departure>
        <AirplaneIconContainer>
          <Icon />
        </AirplaneIconContainer>
        <Arrival>
          <Typography variant="title">
            {dayjs.utc(arrivalDate).format(dateFormat)}
          </Typography>
          <Typography variant="title">
            {dayjs.utc(arrivalDate).format(timeFormat)}
          </Typography>
          <Typography variant="content">{arrivalAirport}</Typography>
        </Arrival>
      </BasicTripInfo>
      <ExtendedTripInfo>
        <LeftColumn>
          {legType !== LegTypes.Outage && (
            <StyledTypography variant="title">
              {t('molecules.Schedule.passengerCount', {
                count: passengerCount,
              })}
            </StyledTypography>
          )}
          {typeof flightTimeInMinutes !== 'undefined' && (
            <StyledTypography variant="title">
              {formattedDuration}
            </StyledTypography>
          )}
          {legType !== LegTypes.Outage &&
            typeof distanceInNauticalMiles !== 'undefined' && (
              <StyledTypography variant="title">
                {t('molecules.Schedule.distanceInNauticalMiles', {
                  distance: format(
                    DEFAULT_NUMBER_FORMAT,
                    distanceInNauticalMiles,
                  ),
                })}
              </StyledTypography>
            )}
          <StyledTypography variant="title">
            {t('molecules.Schedule.finalPrice')}
          </StyledTypography>
          <StyledTypography variant="title">
            {t('molecules.Schedule.totalProfit')}
          </StyledTypography>
        </LeftColumn>
        <RightColumn>
          {legType !== LegTypes.Outage && typeof profit !== 'undefined' && (
            <StyledProfit
              type={profit >= 0 ? 'profit' : 'loss'}
              value={profit}
            />
          )}
          {legType !== LegTypes.Outage && typeof price !== 'undefined' && (
            <StyledPrice value={price} />
          )}
          {legType !== LegTypes.Outage && tripId && (
            <Link
              $disabled={!onTripIdClick}
              onClick={onTripIdClick}
              variant="title"
            >
              {tripId}
            </Link>
          )}
          <StyledPrice value={finalPrice ?? 0} />
          <StyledProfit
            type={totalProfit && totalProfit >= 0 ? 'profit' : 'loss'}
            value={totalProfit ?? 0}
          />
          {legType !== LegTypes.Outage && fl3xxTripNumber && (
            <StyledTypography variant="title">
              {fl3xxTripNumber}
            </StyledTypography>
          )}
          {legType === LegTypes.Outage && fl3xxTaskType && (
            <StyledTypography variant="title">{fl3xxTaskType}</StyledTypography>
          )}
        </RightColumn>
      </ExtendedTripInfo>
    </>
  )
}

const Departure = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
`

const Arrival = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const AirplaneIconContainer = styled.div`
  flex: 1 1 auto;
  min-width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

const BasicTripInfo = styled.div`
  display: flex;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.palette.grey[100]};
`

const StyledFlightIcon = styled(FlightIcon)`
  width: 1rem;
  height: 1rem;
  transform: rotate(90deg);
  color: ${({ theme }) => theme.palette.grey[200]};
`

const StyledOutlinedFlightIcon = styled(FlightOutlinedIcon)`
  width: 1rem;
  height: 1rem;
  transform: rotate(90deg);
  color: ${({ theme }) => theme.palette.grey[200]};
`

const StyledConstructionIcon = styled(ConstructionIcon)`
  width: 1rem;
  height: 1rem;
  color: ${({ theme }) => theme.palette.grey[200]};
`

const ExtendedTripInfo = styled.div`
  display: flex;
`

const LeftColumn = styled.div`
  flex: 1 1 50%;
  text-align: left;
  padding-right: 0.5rem;
`

const RightColumn = styled.div`
  flex: 1 1 50%;
  text-align: right;
`

const StyledTypography = styled(Typography)`
  line-height: 0.875rem;
  white-space: nowrap;
`

const StyledPrice = styled(Price)`
  line-height: 0.875rem;
  font-size: 0.625rem;
`

const StyledProfit = styled(StyledPrice)`
  font-size: 0.875rem;
`

const Link = styled(StyledTypography)<LinkProps>`
  text-decoration: ${({ $disabled }) => ($disabled ? 'none' : 'underline')};
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};

  color: ${({ $disabled, theme }) =>
    $disabled ? theme.palette.grey[600] : theme.palette.primary.main};

  &:hover {
    text-decoration: none;
  }
`

export default ScheduleTooltipContent
