import { AVINODE_REQUEST_TRIP_ID_PREFIX } from '@shared/constants'
import { Request } from '@shared/entities/request.entity'

export function getIsAvinodeRequest(partialRequest: Pick<Request, 'trip_id'>) {
  return partialRequest.trip_id.startsWith(AVINODE_REQUEST_TRIP_ID_PREFIX)
}

export function getTripIdWithoutPrefix(tripId: string) {
  return tripId.split('-')[1]
}

export function getTripIdSource(tripId: string) {
  return tripId.split('-')[0]
}
