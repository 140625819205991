import { OfferStatuses } from '@shared/enums'

// @see https://confluence.inventi.cz/pages/viewpage.action?pageId=62161843#Popt%C3%A1vky&Nab%C3%ADdkyv0.12updated-Popt%C3%A1vkaanab%C3%ADdka-statusy
// @todo Use XState instead
export const offerTransitions: { [key in OfferStatuses]: OfferStatuses[] } = {
  [OfferStatuses.New]: [OfferStatuses.Unhandled, OfferStatuses.Declined],

  [OfferStatuses.Unhandled]: [OfferStatuses.Quoted, OfferStatuses.Declined],

  [OfferStatuses.Draft]: [OfferStatuses.Quoted, OfferStatuses.Declined],

  [OfferStatuses.Quoted]: [
    OfferStatuses.Booked,
    OfferStatuses.BookedCancelled,
    OfferStatuses.Rejected,
  ],

  [OfferStatuses.Booked]: [],

  [OfferStatuses.Cancelled]: [],

  [OfferStatuses.Declined]: [],

  [OfferStatuses.Rejected]: [],

  [OfferStatuses.Storno]: [],

  [OfferStatuses.Processing]: [],

  [OfferStatuses.BookedCancelled]: [],
}
