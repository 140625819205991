import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'

import IconButton from '@material-ui/core/IconButton'
import CheckIcon from '@material-ui/icons/Check'
import DeleteIcon from '@material-ui/icons/Delete'

import ErrorTooltip from '@app/components/atoms/Tooltip/ErrorTooltip'
import Button from '@app/components/atoms/Button/Button'
import TextField from '@app/components/atoms/TextField/TextField'

import { useYupValidationResolver } from '@app/hooks/useYupValidationResolver'
import { PostContactPersonDto } from '@app/utils/api/types'
import useContactPersonFormValidationSchema from '@app/components/organisms/ContactPersonForm/useContactPersonFormValidationSchema'
import { ContactPersonDetailDto } from '@shared/dto/contactPerson.dto'

import {
  transformContactPersonToFormData,
  transformFormDataToPostContactPersonDto,
} from '@app/components/organisms/ContactPersonForm/ContactPersonForm.transformer'

const DEFAULT_CELL_WIDTHS = {
  name: '12rem',
  email: '12rem',
  phone: '12rem',
  mobilePhone: '12rem',
}

export enum ContactPersonFormVariants {
  Create = 'create',
  Edit = 'edit',
}

interface CellProps {
  $width: string
}

export interface ContactPersonFormData {
  name: string
  email: string
  phone: string
  mobilePhone: string
}

interface ContactPersonFormProps {
  onDelete?: () => void
  isLoading?: boolean
  error?: unknown
  contactPerson?: ContactPersonDetailDto
  className?: string
  canSubmitUntouched?: boolean
  variant?: ContactPersonFormVariants
  disabled?: boolean
  onSubmit: (
    postContactPersonPartialDto: Omit<PostContactPersonDto, 'client_id'>,
  ) => void
  cellWidths?: {
    name: string
    email: string
    phone: string
    mobilePhone: string
  }
}

const ContactPersonForm = ({
  contactPerson,
  onSubmit,
  onDelete,
  isLoading,
  error,
  className,
  canSubmitUntouched,
  disabled,
  variant = ContactPersonFormVariants.Create,
  cellWidths = DEFAULT_CELL_WIDTHS,
}: ContactPersonFormProps) => {
  const { t } = useTranslation()

  const validationSchema = useContactPersonFormValidationSchema()
  const validationResolver = useYupValidationResolver(validationSchema)

  const transformedContactPerson = transformContactPersonToFormData(
    contactPerson ?? null,
  )

  const { handleSubmit, formState, control, reset } =
    useForm<ContactPersonFormData>({
      resolver: validationResolver,
      defaultValues: transformedContactPerson,
    })

  const { errors, isDirty } = formState

  const handleSubmitClick = (values: ContactPersonFormData) => {
    const transformedValues = transformFormDataToPostContactPersonDto(values)

    onSubmit(transformedValues)
  }

  useEffect(() => {
    if (!isLoading && !error) {
      reset(transformedContactPerson)
    }
  }, [error, isLoading, JSON.stringify(transformedContactPerson)])

  return (
    <Form
      onSubmit={handleSubmit(handleSubmitClick)}
      className={className}
      noValidate
    >
      <Controller<ContactPersonFormData, 'name'>
        name="name"
        control={control}
        render={({ field: { name, value, onChange, onBlur } }) => {
          return (
            <ErrorTooltip title={errors.name?.message}>
              <StyledTextField
                size="normal"
                placeholder={t('organisms.ContactPersonForm.nameLabel')}
                name={name}
                value={value ?? ''}
                onChange={onChange}
                onBlur={onBlur}
                error={Boolean(formState.errors.name)}
                $width={cellWidths.name}
                disabled={disabled}
                label={
                  variant === ContactPersonFormVariants.Create
                    ? t('organisms.ContactPersonForm.namePlaceholder')
                    : undefined
                }
                inputProps={{
                  min: 0,
                  step: 10,
                  'data-testid': 'ContactPersonForm__name-input',
                }}
              />
            </ErrorTooltip>
          )
        }}
      />
      <Controller<ContactPersonFormData, 'email'>
        name="email"
        control={control}
        render={({ field: { name, value, onChange, onBlur } }) => {
          return (
            <ErrorTooltip title={errors.email?.message}>
              <StyledTextField
                size="normal"
                placeholder={t('organisms.ContactPersonForm.emailPlaceholder')}
                name={name}
                value={value ?? ''}
                onChange={onChange}
                onBlur={onBlur}
                error={Boolean(formState.errors.email)}
                $width={cellWidths.email}
                disabled={disabled}
                label={
                  variant === ContactPersonFormVariants.Create
                    ? t('organisms.ContactPersonForm.emailLabel')
                    : undefined
                }
                inputProps={{
                  min: 0,
                  step: 10,
                  'data-testid': 'ContactPersonForm__email-input',
                }}
              />
            </ErrorTooltip>
          )
        }}
      />
      <Controller<ContactPersonFormData, 'phone'>
        name="phone"
        control={control}
        render={({ field: { name, value, onChange, onBlur } }) => {
          return (
            <ErrorTooltip title={errors.phone?.message}>
              <StyledTextField
                size="normal"
                placeholder={t('organisms.ContactPersonForm.phonePlaceholder')}
                name={name}
                value={value ?? ''}
                onChange={onChange}
                onBlur={onBlur}
                error={Boolean(formState.errors.phone)}
                $width={cellWidths.phone}
                disabled={disabled}
                label={
                  variant === ContactPersonFormVariants.Create
                    ? t('organisms.ContactPersonForm.phoneLabel')
                    : undefined
                }
                inputProps={{
                  min: 0,
                  step: 10,
                  'data-testid': 'ContactPersonForm__phone-input',
                }}
              />
            </ErrorTooltip>
          )
        }}
      />
      <Controller<ContactPersonFormData, 'mobilePhone'>
        name="mobilePhone"
        control={control}
        render={({ field: { name, value, onChange, onBlur } }) => {
          return (
            <ErrorTooltip title={errors.mobilePhone?.message}>
              <StyledTextField
                size="normal"
                placeholder={t(
                  'organisms.ContactPersonForm.mobilePhonePlaceholder',
                )}
                name={name}
                value={value ?? ''}
                onChange={onChange}
                onBlur={onBlur}
                error={Boolean(formState.errors.mobilePhone)}
                $width={cellWidths.mobilePhone}
                disabled={disabled}
                label={
                  variant === ContactPersonFormVariants.Create
                    ? t('organisms.ContactPersonForm.mobilePhoneLabel')
                    : undefined
                }
                inputProps={{
                  min: 0,
                  step: 10,
                  'data-testid': 'ContactPersonForm__mobilePhone-input',
                }}
              />
            </ErrorTooltip>
          )
        }}
      />
      <ButtonsContainer>
        {variant === ContactPersonFormVariants.Edit ? (
          <IconButton
            disabled={
              (!canSubmitUntouched && (!isDirty || isLoading)) || disabled
            }
            type="submit"
          >
            <CheckIcon />
          </IconButton>
        ) : (
          <Button
            disabled={
              (!canSubmitUntouched && (!isDirty || isLoading)) || disabled
            }
            type="submit"
          >
            {t('organisms.ContactPersonForm.add')}
          </Button>
        )}
        {onDelete && (
          <IconButton disabled={isLoading || disabled} onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        )}
      </ButtonsContainer>
    </Form>
  )
}

const Form = styled.form`
  display: flex;
  align-items: flex-end;
`

const StyledTextField = styled(TextField)<CellProps>`
  width: ${({ $width }) => `calc(${$width} - 0.5rem)`};
  margin-right: 0.5rem;
`

const ButtonsContainer = styled.div`
  display: flex;
`

export default ContactPersonForm
