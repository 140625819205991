export const ORDER_DIRECTIONS = ['ASC', 'DESC'] as const

export type OrderDirections = (typeof ORDER_DIRECTIONS)[number]

export const LOCALES = ['en'] as const

export type Locales = (typeof LOCALES)[number]

export const DEFAULT_ORDER_DIRECTION: OrderDirections = 'ASC'

export const DEFAULT_LOCALE: Locales = 'en'

export const DEFAULT_PAGE = 1

export const DEFAULT_LIMIT = 50
