import React from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import LegFieldGroup, {
  LegFieldGroupProps,
} from '@app/components/organisms/LegEditorForm/LegFieldGroup/LegFieldGroup'

import { useActiveUser } from '@app/hooks/useActiveUser'
import { getEditorDefaultValues } from '@app/components/organisms/LegEditorForm/LegEditorForm.utils'

import { BaseLegDetailDto, OfferDto } from '@shared/dto/requests.dto'
import { AircraftDetailDto } from '@shared/dto/aircraft.dto'
import { DisplayTimeTypes } from '@shared/enums'

import {
  getLocalDateIgnoringTimezone,
  getTimezoneAwareDateFromUTC,
} from '@app/utils/dateUtils'

dayjs.extend(utc)

interface RemovedLegsListProps {
  offer: OfferDto
  legs: BaseLegDetailDto[]
  aircraft: AircraftDetailDto
  onIgnoreSingleLegFromOptimization?: LegFieldGroupProps['onIgnoreSingleLegFromOptimization']
  onIgnoreAllLegsFromOptimization?: LegFieldGroupProps['onIgnoreAllLegsFromOptimization']
}

const RemovedLegsList = ({
  legs,
  aircraft,
  offer,
  onIgnoreAllLegsFromOptimization,
  onIgnoreSingleLegFromOptimization,
}: RemovedLegsListProps) => {
  const activeUser = useActiveUser()

  const values = getEditorDefaultValues(legs, aircraft, {
    timeDisplay: activeUser?.display_time_type ?? DisplayTimeTypes.UTC,
  })

  return (
    <>
      {legs.map((leg, index) => (
        <LegFieldGroup
          leg={values.legs[index]}
          isDeleted
          readonly
          key={`${leg.departure_airport_id}-${leg.arrival_airport_id}-${index}`}
          onIgnoreAllLegsFromOptimization={onIgnoreAllLegsFromOptimization}
          onIgnoreSingleLegFromOptimization={onIgnoreSingleLegFromOptimization}
          extras={leg}
          index={index}
          offer={offer}
          fields={{
            type: { value: leg.type },
            arrivalAirport: { value: leg.arrival_airport },
            departureAirport: { value: leg.departure_airport },
            passengerCount: { value: leg.passenger_count },
            aircraft: { value: aircraft },
            departureDate: {
              value: getTimezoneAwareDateFromUTC(
                getLocalDateIgnoringTimezone(leg.departure_date),
                leg.arrival_airport.timezone,
                activeUser?.display_time_type ?? DisplayTimeTypes.UTC,
              ),
            },
            departureTime: {
              value: getTimezoneAwareDateFromUTC(
                getLocalDateIgnoringTimezone(leg.departure_date),
                leg.arrival_airport.timezone,
                activeUser?.display_time_type ?? DisplayTimeTypes.UTC,
              ),
            },
            arrivalDate: {
              value: getTimezoneAwareDateFromUTC(
                getLocalDateIgnoringTimezone(leg.arrival_date),
                leg.arrival_airport.timezone,
                activeUser?.display_time_type ?? DisplayTimeTypes.UTC,
              ),
            },
            arrivalTime: {
              value: getTimezoneAwareDateFromUTC(
                getLocalDateIgnoringTimezone(leg.arrival_date),
                leg.arrival_airport.timezone,
                activeUser?.display_time_type ?? DisplayTimeTypes.UTC,
              ),
            },
          }}
        />
      ))}
    </>
  )
}

export default RemovedLegsList
