import { getLegTurnaroundTimeInMinutes } from '@shared/utils/legUtils'
import * as Entities from '@shared/entities'

const getMarketplaceExtensionDepartureDateBoundaryBasedOnPrecedingItem = (
  precedingScheduleItem: Entities.Schedule,
  aircraft: Entities.Aircraft,
) => {
  if (!precedingScheduleItem) {
    return
  }

  const resDate = new Date(
    precedingScheduleItem.marketplace_arrival_date &&
    precedingScheduleItem.marketplace_arrival_date >
      precedingScheduleItem.arrival_date
      ? precedingScheduleItem.marketplace_arrival_date
      : precedingScheduleItem.arrival_date,
  )

  const toReturn = new Date(resDate.getTime())
  toReturn.setMinutes(
    toReturn.getMinutes() + aircraft.turnaround_before_empty_leg_in_minutes,
  )

  return toReturn
}

const getMarketplaceExtensionArrivalDateBoundaryBasedOnSucceedingItem = (
  succeedingScheduleItem: Entities.Schedule,
  aircraft: Entities.Aircraft,
) => {
  if (!succeedingScheduleItem) {
    return
  }

  const turnaroundInMinutes = getLegTurnaroundTimeInMinutes(
    succeedingScheduleItem.type,
    aircraft,
  )

  const resDate = new Date(
    succeedingScheduleItem.marketplace_departure_date &&
    succeedingScheduleItem.marketplace_departure_date <
      succeedingScheduleItem.departure_date
      ? succeedingScheduleItem.marketplace_departure_date
      : succeedingScheduleItem.departure_date,
  )

  const toReturn = new Date(resDate.getTime())
  toReturn.setMinutes(toReturn.getMinutes() - turnaroundInMinutes)

  return toReturn
}

export const getMarketplaceExtensionDepartureDateBoundary = (
  scheduleItem: Entities.Schedule,
  precedingScheduleItem: Entities.Schedule,
  aircraft: Entities.Aircraft,
) => {
  const minBoundary = new Date(new Date(scheduleItem.departure_date).getTime())
  minBoundary.setMinutes(
    minBoundary.getMinutes() - aircraft.marketingLegDurationMinutes,
  )

  const boundary =
    getMarketplaceExtensionDepartureDateBoundaryBasedOnPrecedingItem(
      precedingScheduleItem,
      aircraft,
    )

  if (!boundary || boundary < minBoundary) {
    return minBoundary
  }

  return boundary
}

export const getMarketplaceExtensionArrivalDateBoundary = (
  scheduleItem: Entities.Schedule,
  succeedingScheduleItem: Entities.Schedule,
  aircraft: Entities.Aircraft,
) => {
  const maxBoundary = new Date(new Date(scheduleItem.arrival_date).getTime())
  maxBoundary.setMinutes(
    maxBoundary.getMinutes() + aircraft.marketingLegDurationMinutes,
  )

  const boundary =
    getMarketplaceExtensionArrivalDateBoundaryBasedOnSucceedingItem(
      succeedingScheduleItem,
      aircraft,
    )

  if (!boundary || boundary > maxBoundary) {
    return maxBoundary
  }

  return boundary
}
