import React, { ReactNode, useMemo } from 'react'
import styled from 'styled-components'
import produce from 'immer'
import { navigate } from 'gatsby'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import AirplaneIcon from '@material-ui/icons/AirplanemodeActiveOutlined'
import AssignmentIcon from '@material-ui/icons/Assignment'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import GpsFixedIcon from '@material-ui/icons/GpsFixed'
import GroupIcon from '@material-ui/icons/Group'
import TimelineIcon from '@material-ui/icons/Timeline'
import EventNote from '@material-ui/icons/EventNote'
import SettingsIcon from '@material-ui/icons/Settings'

import Typography from '@app/components/atoms/Typography/Typography'
import LoadingSpinner from '@app/components/atoms/LoadingSpinner/LoadingSpinner'
import Logo from '@app/components/atoms/Logo/Logo'
import SideMenu from '@app/components/organisms/GeneralLayout/SideMenu'

import { Actions, Routes } from '@shared/enums'
import { useCanUser } from '@app/hooks/useCanUser'
import { useSelector } from 'react-redux'
import { selectUserOperators } from '@app/store/core/userOperators/userOperators.selectors'
import { selectUserInfo } from '@app/store/core/userInfo/userInfo.selectors'
import { PERMISSIONS } from '@shared/constants'
import useUnreadMessages from '@app/hooks/useUnreadMessagesCount'

interface GeneralLayoutProps {
  children: ReactNode
  title: string
  loading?: boolean
  className?: string
}

export interface IMenuItem {
  id: string
  icon: JSX.Element
  label?: string
  href?: string
  as?: string
  testId?: string
  alertIcon?: JSX.Element
}

const GeneralLayout = ({
  children,
  title,
  loading,
  className,
}: GeneralLayoutProps): JSX.Element => {
  const canUser = useCanUser()
  const userInfo = useSelector(selectUserInfo)
  const userOperators = useSelector(selectUserOperators)

  const canUserDisplayFleet = canUser(Actions.GetAircraft)
  const canUserDisplayClients = canUser(Actions.GetClient)
  const canUserDisplayCompany = canUser(Actions.GetCompany)
  const canUserDisplayUsers = canUser(Actions.GetUser)
  const canUserDisplayRequests = canUser(Actions.GetRequest)
  const canUserDisplayOffers = canUser(Actions.GetOffer)
  const canUserDisplaySchedule = canUser(Actions.GetSchedule)

  const { data: unreadMessagesCount } = useUnreadMessages()

  const canUserDisplayAnyOperator = userOperators?.some((operator) =>
    canUser(Actions.GetOperator, operator.id),
  )

  const canUserDisplayAirports = userInfo?.user_roles.some((role) =>
    PERMISSIONS[Actions.GetAirport].includes(role.role),
  )

  const primaryMenuItems = useMemo((): Array<IMenuItem> => {
    const draft: Array<IMenuItem> = []
    if (canUserDisplayRequests && canUserDisplayOffers) {
      draft.push({
        id: 'requests',
        label: 'Requests',
        icon: <AssignmentIcon color="inherit" />,
        href: Routes.Requests,
        as: Routes.Requests,
        testId: 'GeneralLayout__requests-menu-item',
      })

      draft.push({
        id: 'bookings',
        label: 'Bookings',
        icon: <AttachMoneyIcon color="inherit" />,
        href: '/bookings',
        as: '/bookings',
        testId: 'GeneralLayout__bookings-menu-item',
      })
    }

    if (canUserDisplaySchedule) {
      draft.push({
        id: 'schedule',
        label: 'Schedule',
        icon: <EventNote color="inherit" />,
        href: Routes.Schedule,
        as: Routes.Schedule,
        testId: 'GeneralLayout__schedule-menu-item',
      })
    }

    if (canUserDisplayFleet) {
      draft.push({
        id: 'fleet',
        label: 'Fleet',
        icon: <AirplaneIcon color="inherit" />,
        href: Routes.Fleet,
        as: Routes.Fleet,
        testId: 'GeneralLayout__fleet-menu-item',
      })
    }

    if (canUserDisplayAirports) {
      draft.push({
        id: 'airports',
        label: 'Airports',
        icon: <GpsFixedIcon color="inherit" />,
        href: Routes.Airports,
        as: Routes.Airports,
        testId: 'GeneralLayout__airports-menu-item',
      })
    }

    if (canUserDisplayUsers) {
      draft.push({
        id: 'myTeam',
        label: 'My team',
        icon: <GroupIcon color="inherit" />,
        href: '/my-team',
        as: '/my-team',
        testId: 'GeneralLayout__my-team-menu-item',
      })
    }

    draft.push({
      id: 'reports',
      label: 'Reports',
      icon: <TimelineIcon color="inherit" />,
      href: Routes.Reports,
      as: Routes.Reports,
      testId: 'GeneralLayout__reports-menu-item',
    })

    if (canUserDisplayClients) {
      draft.push({
        id: 'clients',
        label: 'Clients',
        icon: <GroupIcon color="inherit" />,
        href: Routes.Clients,
        as: Routes.Clients,
        testId: 'GeneralLayout__clients-menu-item',
      })
    }
    return draft
  }, [
    unreadMessagesCount,
    canUserDisplayFleet,
    canUserDisplayClients,
    canUserDisplayCompany,
    canUserDisplayUsers,
    canUserDisplayRequests,
    canUserDisplayOffers,
    canUserDisplaySchedule,
    canUserDisplayAirports,
  ])

  const secondaryMenuItems = produce<IMenuItem[]>([], (draft) => {
    if (canUserDisplayAnyOperator || canUserDisplayCompany) {
      draft.push({
        id: 'settings',
        icon: <SettingsIcon color="inherit" />,
        href: Routes.Settings,
        as: Routes.Settings,
        testId: 'GeneralLayout__settings-menu-item',
      })
    }
  })

  const onItemClick = async (itemId: string) => {
    const target =
      primaryMenuItems.find((item) => item.id === itemId) ??
      secondaryMenuItems.find((item) => item.id === itemId)

    if (!target) {
      console.warn(`Item '${itemId}' doesn't exist!`)

      return
    }

    if (target.href) {
      return navigate(target.href)
    }
  }

  return (
    <LayoutGrid container className={className}>
      <StyledSideMenu
        onItemClick={onItemClick}
        primaryMenuItems={primaryMenuItems}
        secondaryMenuItems={secondaryMenuItems}
      />
      <StyledBox
        pr={4}
        py={4}
        pl="30px"
        display="flex"
        flexDirection="column"
        flex={1}
        zIndex={1}
      >
        <Box my={1}>
          <StyledTypography variant="heading">{title}</StyledTypography>
        </Box>

        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width={1}
            flex={1}
          >
            <LoadingSpinner loading>
              <Logo width="75px" />
            </LoadingSpinner>
          </Box>
        ) : (
          children
        )}
      </StyledBox>
    </LayoutGrid>
  )
}

const StyledSideMenu = styled(SideMenu)`
  z-index: 1;
`

const LayoutGrid = styled(Grid)`
  min-height: 100vh;
`

const StyledTypography = styled(Typography)`
  font-weight: 600;
`

const StyledBox = styled(Box)`
  margin-left: 6.875rem;
`

export default GeneralLayout
