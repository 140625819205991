import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import produce from 'immer'
import FileDownload from 'js-file-download'
import { useSnackbar } from 'notistack'

import CancelIcon from '@material-ui/icons/Cancel'
import SearchIcon from '@material-ui/icons/Search'

import GeneralLayout from '@app/components/organisms/GeneralLayout/GeneralLayout'
import CreateRequestDialog from '@app/components/organisms/CreateRequestDialog/CreateRequestDialog'
import Button from '@app/components/atoms/Button/Button'
import Logo from '@app/components/atoms/Logo/Logo'
import RequestsGridView from '@app/components/molecules/RequestsGridView/RequestsGridView'
import RequestDetail from '@app/components/organisms/RequestDetail/RequestDetail'

import RequestsFilters, {
  RequestsFiltersProps,
} from '@app/components/pages/Requests/RequestsFilters'

import { Actions, OfferStatuses } from '@shared/enums'
import { DATA_GRID_DATA_ROW_ID_ATTRIBUTE } from '@app/components/atoms/DataGrid/DataGrid'
import { getAvinodeCompareUrl } from '@app/utils/avinodeUtils'
import { setOpenOfferIdAction } from '@app/store/pages/requests/requestDetail/requestDetail.actions'
import { ContextMenuItem } from '@app/components/atoms/DataGrid/useRowActionColumn'
import { useCanUser } from '@app/hooks/useCanUser'
import { api } from '@app/utils/api/api'
import { selectSelectedOperator } from '@app/store/core/userOperators/userOperators.selectors'
import { selectCreateRequestIsLoading } from '@app/store/pages/requests/createRequest/createRequest.selectors'

import {
  selectOpenRequestId,
  selectRequestPageVariant,
  selectRequestsListData,
  selectRequestsListError,
  selectRequestsListFilters,
  selectRequestsListIsLoading,
  selectRequestsListOrderBy,
  selectRequestsListOrderDirection,
  selectRequestsListTotal,
} from '@app/store/pages/requests/requestList/requestList.selectors'

import {
  declineRequestAction,
  getRequestsListDataFirstPageAction,
  getRequestsListDataNextPageAction,
  resetRequestListAction,
  resetRequestsListFiltersAction,
  setOpenRequestIdAction,
  setRequestPageVariantAction,
  setRequestsListFiltersAction,
  setRequestsListSortAction,
  startRequestsListPollingAction,
  stopRequestsListPollingAction,
} from '@app/store/pages/requests/requestList/requestList.actions'
import useUnreadMessages from '@app/hooks/useUnreadMessagesCount'

const GATSBY_BASE_AVINODE_URL = process.env.GATSBY_BASE_AVINODE_URL

export enum RequestPageVariants {
  Requests = 'requests',
  Bookings = 'bookings',
}

interface RequestsProps extends RouteComponentProps {
  variant: RequestPageVariants
}

const Requests = ({ variant, ...props }: RequestsProps): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar()
  const { refetch } = useUnreadMessages()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const canUser = useCanUser()

  const canUserUpdateOfferStatus = canUser(Actions.UpdateOfferStatus)

  const data = useSelector(selectRequestsListData)
  const error = useSelector(selectRequestsListError)
  const filters = useSelector(selectRequestsListFilters)
  const isLoading = useSelector(selectRequestsListIsLoading)
  const orderBy = useSelector(selectRequestsListOrderBy)
  const orderDirection = useSelector(selectRequestsListOrderDirection)
  const total = useSelector(selectRequestsListTotal)
  const openRequestId = useSelector(selectOpenRequestId)
  const requestVariant = useSelector(selectRequestPageVariant)

  const isCreatingRequest = useSelector(selectCreateRequestIsLoading)

  const selectedOperator = useSelector(selectSelectedOperator)

  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const handleFilterChange: RequestsFiltersProps['onChange'] = (
    partialNextFilters,
    debounceInMilliseconds,
  ) => {
    if (
      partialNextFilters.statuses &&
      partialNextFilters.statuses.length > 0 &&
      !partialNextFilters.statuses.includes(OfferStatuses.Quoted)
    ) {
      partialNextFilters.reserved = false
    }
    dispatch(
      setRequestsListFiltersAction(partialNextFilters, debounceInMilliseconds),
    )
  }

  const handleRequestCollapseAnimationFinished = (requestId: number) => {
    const $target = window.document.querySelector(
      `tr[${DATA_GRID_DATA_ROW_ID_ATTRIBUTE}="${requestId}"]`,
    )

    if (!$target) {
      return
    }

    setTimeout(() => {
      $target.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      })
    }, 0)
  }

  const handleExportButtonClick = async () => {
    try {
      if (!selectedOperator) {
        throw new Error('Operator has to be selected')
      }

      const { data } = await api.getRequestsCsv({
        operator_id: String(selectedOperator.id),
        orderBy,
        orderDirection,
        ...filters,
      })

      FileDownload(data, t('pages.Requests.exportedCsvFileName'))
    } catch (error) {
      enqueueSnackbar(t('pages.Requests.exportRequestsError'), {
        variant: 'error',
      })
    }
  }

  const handleDeclineRequest = async (request_id: number) => {
    dispatch(declineRequestAction(request_id))
    return api.markMessagesAsRead({ request_id }).then(() => refetch())
  }

  useEffect(() => {
    if (Array.isArray(data)) {
      dispatch(resetRequestListAction())
    }

    dispatch(setRequestPageVariantAction(variant))

    switch (variant) {
      case RequestPageVariants.Bookings:
        dispatch(resetRequestsListFiltersAction())

        dispatch(
          setRequestsListFiltersAction({
            statuses: [OfferStatuses.Booked, OfferStatuses.BookedCancelled],
          }),
        )

        break

      case RequestPageVariants.Requests:
        dispatch(resetRequestsListFiltersAction())
        dispatch(getRequestsListDataFirstPageAction())

        break
    }
  }, [variant])

  useEffect(() => {
    dispatch(startRequestsListPollingAction())

    return () => {
      dispatch(stopRequestsListPollingAction())
    }
  }, [])

  useEffect(() => {
    return () => {
      dispatch(setOpenRequestIdAction(null))
      dispatch(setOpenOfferIdAction(null))
    }
  }, [])

  useEffect(() => {
    dispatch(getRequestsListDataFirstPageAction())
  }, [])

  return (
    <GeneralLayout
      loading={false}
      title={
        variant === RequestPageVariants.Bookings
          ? t('pages.Bookings.title')
          : t('pages.Requests.title')
      }
      {...props}
    >
      <RequestsFilters
        variant={variant}
        filters={filters}
        onChange={handleFilterChange}
      />
      {variant === requestVariant && (
        <RequestsGridView
          isExportable
          onExportButtonClick={handleExportButtonClick}
          collapsedRowId={openRequestId}
          onTableRowClick={(nextValue) => {
            const isClose = openRequestId === nextValue

            dispatch(setOpenRequestIdAction(isClose ? null : nextValue))
          }}
          getCollapsedContainer={() => {
            if (openRequestId === null) {
              return
            }

            return (
              <RequestDetail
                requestId={openRequestId}
                onCollapseAnimationFinished={() =>
                  handleRequestCollapseAnimationFinished(openRequestId)
                }
              />
            )
          }}
          orderBy={orderBy}
          orderDirection={orderDirection}
          data={data}
          total={total}
          isLoading={isLoading || isCreatingRequest}
          error={error}
          getContextMenu={({ avinode_id, status }) => {
            return produce<ContextMenuItem[]>([], (draft) => {
              if (
                canUserUpdateOfferStatus &&
                [
                  OfferStatuses.New,
                  OfferStatuses.Unhandled,
                  OfferStatuses.Draft,
                ].includes(status)
              ) {
                draft.push({
                  name: t('pages.Requests.decline'),
                  icon: <CancelIcon fontSize="small" />,
                  testId: 'Requests__decline-request-context-menu-item',
                  onClick: (id: number) => handleDeclineRequest(id),
                })
              }

              if (
                avinode_id &&
                [
                  OfferStatuses.New,
                  OfferStatuses.Unhandled,
                  OfferStatuses.Draft,
                  OfferStatuses.Quoted,
                  OfferStatuses.Rejected,
                ].includes(status)
              ) {
                draft.push({
                  name: t('pages.Requests.compareWithAvinode'),
                  icon: <SearchIcon fontSize="small" />,
                  testId: 'Requests__compare-with-avinode-context-menu-item',
                  onClick: () => {
                    window.open(
                      getAvinodeCompareUrl(
                        GATSBY_BASE_AVINODE_URL as string,
                        avinode_id,
                      ),
                      '_blank',
                    )
                  },
                })
              }
            })
          }}
          onNextDataRequested={() =>
            dispatch(getRequestsListDataNextPageAction())
          }
          onSortChange={(sort) => dispatch(setRequestsListSortAction(sort))}
        />
      )}
      {variant !== RequestPageVariants.Bookings && (
        <>
          <CreateRequestButton
            size="xlarge"
            round
            onClick={() => setIsDialogOpen(true)}
            data-testid="Requests__create-request-button"
          >
            <StyledLogo width="30px" />
            {t('pages.Requests.createRequest')}
          </CreateRequestButton>
          <CreateRequestDialog
            open={isDialogOpen}
            onClose={() => setIsDialogOpen(false)}
          />
        </>
      )}
    </GeneralLayout>
  )
}

const CreateRequestButton = styled(Button)`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 4;
`

const StyledLogo = styled(Logo)`
  color: ${({ theme }) => theme.palette.common.white};
  padding-right: 0.5rem;
`

export default Requests
