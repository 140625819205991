/**
 * @namespace OfferUtils
 */

import format from 'number-format.js'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

import { getIsAvinodeRequest } from '@shared/v2/utils/request.utils'
import { Leg } from '@shared/entities/leg.entity'
import { Offer } from '@shared/entities/offer.entity'
import { LegTypes } from '@shared/enums'

import {
  DEFAULT_DISTANCE_UNIT,
  DEFAULT_NUMBER_FORMAT,
  DEFAULT_PRICE_FORMAT_WITHOUT_CURRENCY,
} from '@shared/constants'

dayjs.extend(duration)

export function getIsAvinodeOffer(offer: Offer): boolean {
  if (!offer.request) {
    throw new Error(`Relation 'offer.request' must be present`)
  }

  return getIsAvinodeRequest(offer.request)
}

export function getIsActiveAvinodeOffer(offer: Offer): boolean {
  if (!offer.request) {
    throw new Error(`Relation 'offer.request' must be present`)
  }

  if (!offer.request.operator) {
    throw new Error(`Relation 'offer.request.operator' must be present`)
  }

  if (!offer.aircraft) {
    throw new Error(`Relation 'offer.aircraft' must be present`)
  }

  const isAvinodeOffer = getIsAvinodeOffer(offer)

  return (
    isAvinodeOffer &&
    offer.request.operator.is_avinode_enabled &&
    offer.aircraft.is_connected_to_avinode
  )
}

export function getFormattedPrice(price: number, currency: string): string {
  return format(`${DEFAULT_PRICE_FORMAT_WITHOUT_CURRENCY} ${currency}`, price)
}

/**
 * @returns Formatted input as a duration
 *
 * @todo [Tech] Move to format.utils.ts
 *
 * @example
 * getFormattedDuration(135) // '2:15h'
 * getFormattedDuration(65)  // '1:05h'
 * getFormattedDuration(5)   // '0:05h'
 */
export function getFormattedDuration(minutes: number): string {
  const duration = dayjs.duration({ minutes })

  const h = Math.floor(duration.asHours())

  const m = String(
    Math.floor(duration.subtract(h, 'hours').asMinutes()),
  ).padStart(2, '0')

  return `${h}:${m}h`
}

/**
 * @returns Formatted input as a distance
 *
 * @todo [Tech] Move to leg.utils.ts
 *
 * @example
 * getFormattedDistance(20_000) // '20 000NM'
 *
 * @todo [Tech] Move to format.utils.ts
 */
export function getFormattedDistance(distance: number): string {
  return `${format(DEFAULT_NUMBER_FORMAT, distance)}${DEFAULT_DISTANCE_UNIT}`
}

/**
 * @returns Cancelled legs for a given cancellation offer ID
 *
 * @example
 * const legs = [
 *   { id: 1, type: 'removed', remove_leg: { offer_id: 1 } },
 *   { id: 2, type: 'removed', remove_leg: { offer_id: 2 } },
 *   { id: 3, type: 'occupied', remove_leg: undefined },
 * ]
 *
 * const cancellationOfferId = 1
 *
 * getCancelledLegs(legs, cancellationOfferId) // [{ id: 1, type: 'removed', remove_leg: { offer_id: 1 } }]
 */
export function getCancelledLegs<
  T extends Pick<Leg, 'type' | 'remove_leg_id' | 'remove_leg'>,
>(legs: T[], cancellationOfferId?: number, rebookingOfferId?: number): T[] {
  return legs.filter((leg) => {
    const isRemovedLeg = leg.type === LegTypes.Removed

    const removeLegIdExists = Boolean(leg.remove_leg_id)

    const isCancellationLeg =
      leg.remove_leg && leg.remove_leg.offer_id === cancellationOfferId

    const isRebookingLeg =
      leg.remove_leg && leg.remove_leg.offer_id === rebookingOfferId

    return (
      isRemovedLeg && removeLegIdExists && (isCancellationLeg || isRebookingLeg)
    )
  })
}

/**
 * @returns Optimized legs for a given cancellation or rebooking offer ID
 *
 * @todo [Tech] Move to leg.utils.ts
 *
 * @example
 * const legs = [
 *   { id: 1, type: 'removed', remove_leg_id: 5, remove_leg: { offer_id: 1 } },
 *   { id: 2, type: 'removed', remove_leg_id: 6, remove_leg: { offer_id: 2 } },
 *   { id: 3, type: 'removed', remove_leg_id: null, remove_leg: undefined },
 *   { id: 4, type: 'occupied', remove_leg_id: null, remove_leg: undefined },
 * ]
 *
 * const cancellationOfferId = 1
 * const rebookingOfferId = undefined
 *
 * getOptimizedLegs(legs, cancellationOfferId, rebookingOfferId)
 *
 * // Result:
 * // [{ id: 2, type: 'removed', remove_leg: { offer_id: 2 } }]
 *
 * @example
 * const legs = [
 *   { id: 1, type: 'removed', remove_leg_id: 5, remove_leg: { offer_id: 1 } },
 *   { id: 2, type: 'removed', remove_leg_id: 6, remove_leg: { offer_id: 2 } },
 *   { id: 3, type: 'removed', remove_leg_id: null, remove_leg: undefined },
 *   { id: 4, type: 'occupied', remove_leg_id: null, remove_leg: undefined },
 * ]
 *
 * const cancellationOfferId = undefined
 * const rebookingOfferId = 2
 *
 * getOptimizedLegs(legs, cancellationOfferId, rebookingOfferId)
 *
 * // Result:
 * // [{ id: 1, type: 'removed', remove_leg: { offer_id: 1 } }]
 */
export function getOptimizedLegs<
  T extends Pick<Leg, 'type' | 'remove_leg' | 'remove_leg_id'>,
>(legs: T[], cancellationOfferId?: number, rebookingOfferId?: number): T[] {
  return legs.filter((leg) => {
    const isRemovedLeg = leg.type === LegTypes.Removed

    const removeLegIdExists = Boolean(leg.remove_leg_id)

    const isCancellationLeg =
      leg.remove_leg && leg.remove_leg.offer_id === cancellationOfferId

    const isRebookingLeg =
      leg.remove_leg && leg.remove_leg.offer_id === rebookingOfferId

    return (
      isRemovedLeg && removeLegIdExists && !isCancellationLeg && !isRebookingLeg
    )
  })
}

/**
 * @returns Leg removed from a given offer ID
 *
 * @todo [Tech] Remove 'Partial<Pick<Leg, 'offer_id'>>' in favor of
 * <Pick, 'offer_id'>
 *
 * @todo [Tech] Move to leg.utils.ts
 *
 * @example
 * const legs = [
 *   { id: 1, type: 'removed', offerId: 1, remove_leg_id: 4, remove_leg: { offer_id: 1 } },
 *   { id: 2, type: 'removed', offerId: 1, remove_leg_id: null, remove_leg: undefined },
 *   { id: 3, type: 'occupied', offerId: 1, remove_leg_id: null, remove_leg: undefined },
 * ]
 *
 * const offerId = 1
 *
 * getCancelledLegs(legs, cancellationOfferId)
 *
 * // [{ id: 2, type: 'removed', offerId: 1, remove_leg_id: null, remove_leg: undefined }]
 */
export function getLegsRemovedFromOffer<
  T extends Pick<Leg, 'type' | 'remove_leg_id'> &
    Partial<Pick<Leg, 'offer_id'>>,
>(legs: T[], offerId: number): T[] {
  return legs.filter((leg) => {
    const isRemovedLeg = leg.type === LegTypes.Removed
    const isLegFromInputOfferId = leg.offer_id === offerId
    const removeLegIdExists = Boolean(leg.remove_leg_id)

    return isRemovedLeg && isLegFromInputOfferId && !removeLegIdExists
  })
}
